import React from "react";
import Carousel from "react-multi-carousel";
import { IHost } from "@Interfaces";
import { useIsDesktop } from "repoV2/utils/common/render/screen";
import UnlockPortfolioCard from "./UnlockPortfolioCard";
import { IPortfolio } from "./portfolio";
import styles from "./portfolio.module.scss";
import CarouselCustomDots from "../../CarouselCustomDots";

const UnlockPortfolio = ({
    galleryData,
    onGalleryItemClick,
    color,
    pauseGalleryAutoplay,
}: IPortfolio.IProps): JSX.Element | null => {
    const isDesktop = useIsDesktop();

    function groupArray(array: any[], groupSize: number) {
        const result = [];

        for (let i = 0; i < array.length; i += groupSize) {
            const group = array.slice(i, i + groupSize);
            result.push(group);
        }

        return result;
    }

    const groupedData: IHost.IGalleryItem[][] = groupArray(
        galleryData,
        isDesktop ? 6 : 1
    );

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1301 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1300, min: 901 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 900, min: 0 },
            items: 1,
        },
    };

    const groupedElements = groupedData.map((group, groupIdx) => {
        return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.grid} key={groupIdx}>
                {group.map((item: IHost.IGalleryItem) => {
                    return (
                        <UnlockPortfolioCard
                            data={item}
                            key={item.uuid}
                            onClickMediaCard={() => {
                                onGalleryItemClick(item);
                            }}
                            color={color}
                        />
                    );
                })}
            </div>
        );
    });

    return (
        <div className={styles.root}>
            <Carousel
                responsive={responsive}
                infinite
                arrows={false}
                autoPlay={groupedData.length > 1 && !pauseGalleryAutoplay}
                showDots
                renderDotsOutside
                dotListClass={styles.dotListClass}
                customDot={
                    <CarouselCustomDots
                        index={0}
                        onClick={undefined}
                        active={false}
                    />
                }
            >
                {groupedElements}
            </Carousel>
        </div>
    );
};

export default UnlockPortfolio;
