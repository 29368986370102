/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Carousel from "react-multi-carousel";
import { ITemplate } from "@Templates/ITemplate";
import { SECTION_IDS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { IHost } from "@Interfaces";
import { TestimonialCard } from "./TestimonialCard";
import SectionHeader from "../SectionHeader";
import CarouselCustomDots from "../CarouselCustomDots";
import commonStyles from "../../root/unlock.module.scss";
import styles from "./testimonialsSection.module.scss";

export const TestimonialsSection = ({
    hideComponent,
    title,
    testimonialsData,
    onTestimonialClick,
    pauseTestimonialsAutoplay,
    testimonialsSubtitle,
    testimonialsDescription,
}: ITemplate.ITestimonialsSectionProps) => {
    const { theme } = useUnlockPageContext();
    const responsive = {
        largest: {
            breakpoint: { max: 4000, min: 1351 },
            items: 3,
        },
        large: {
            breakpoint: { max: 1350, min: 1001 },
            items: 3,
        },
        medium: {
            breakpoint: { max: 1000, min: 681 },
            items: 3,
        },
        small: {
            breakpoint: { max: 680, min: 0 },
            items: 1,
        },
    };

    const prepareTestimonialsData = (() => {
        if (testimonialsData.length > 1) {
            const list = [...testimonialsData];
            const lastItem = list?.pop(); // Remove the last item from the array
            if (lastItem) list.unshift(lastItem);
            return list;
        }

        return testimonialsData;
    })();

    const testimonialCards = prepareTestimonialsData?.map(
        (item: IHost.ITestimonialItem) => (
            <TestimonialCard
                key={item.id}
                data={item}
                onTestimonialClick={e => onTestimonialClick(e, item)}
            />
        )
    );

    if (hideComponent) return null;

    const testimonials = (
        <div
            className={classNames(
                styles.container,
                testimonialsData?.length < 3 &&
                    styles.singleTestimonialContainer
            )}
        >
            <SectionHeader
                subtitle={title}
                title={
                    testimonialsSubtitle ||
                    "Success Stories from My Amazing Tribe"
                }
                description={
                    testimonialsDescription ||
                    "The ultimate planning solution for dedicated professionals who want to reach their personal goals"
                }
            />
            {testimonialsData.length > 1 ? (
                <Carousel
                    responsive={responsive}
                    infinite
                    autoPlay={
                        testimonialsData.length > 1 &&
                        !pauseTestimonialsAutoplay
                    }
                    arrows={false}
                    renderDotsOutside
                    dotListClass={styles.dotListClass}
                    showDots
                    partialVisible
                    containerClass={
                        testimonialsData.length > 2
                            ? styles.carouselContainerClass
                            : styles.carouselContainerClassEqual
                    }
                    customDot={
                        <CarouselCustomDots
                            index={0}
                            onClick={undefined}
                            active={false}
                        />
                    }
                >
                    {testimonialCards}
                </Carousel>
            ) : (
                <div className={styles.singleTestimonial}>
                    {testimonialCards}
                </div>
            )}
        </div>
    );

    return (
        <>
            <div
                className={classNames(
                    styles.root,
                    testimonialsData?.length >= 3 ? styles.extraPadding : "",
                    commonStyles.hideInMobile
                )}
                style={{
                    background: `linear-gradient(to bottom, #EBF4F8 60%, ${theme?.theme?.color2} 40%)`,
                }}
            >
                <a
                    id={SECTION_IDS.testimonials}
                    className={styles.sectionAnchor}
                />
                {testimonials}
            </div>
            <div
                className={classNames(
                    styles.root,
                    testimonialsData?.length >= 3 ? styles.extraPadding : "",
                    commonStyles.hideInDesktop
                )}
            >
                <a
                    id={SECTION_IDS.testimonials}
                    className={styles.sectionAnchor}
                />
                {testimonials}
            </div>
        </>
    );
};
