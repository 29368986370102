import { EVENT_CATEGORISATION_TYPE, getEventListingsOfCategory } from "@Utils";
import { IUseCategorisedEventList } from "../ICategorisedEventList";

export const useCategorisedEventList = ({
    allCategories,
    eventListings,
    categoriseType,
}: IUseCategorisedEventList) => {
    const determineCategoryType = (type: number) => {
        return {
            isSkuTypeAndSkuKeywords: [
                EVENT_CATEGORISATION_TYPE.SKU_TYPE,
                EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS,
            ].includes(type),
            isSkuTypeAndKeywords: [
                EVENT_CATEGORISATION_TYPE.KEYWORDS_SKU_TYPE,
                EVENT_CATEGORISATION_TYPE.KEYWORDS,
            ].includes(type),
            isNoCategorisation:
                type === EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION,
        };
    };

    const {
        isSkuTypeAndSkuKeywords,
        isSkuTypeAndKeywords,
        isNoCategorisation,
    } = determineCategoryType(categoriseType);

    if (isSkuTypeAndSkuKeywords) {
        const showKeywords =
            categoriseType === EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS;

        return allCategories.map(({ title: category, listingType }, index) => {
            const eventListProp = getEventListingsOfCategory(
                category,
                categoriseType,
                eventListings,
                {
                    listingType,
                }
            );

            if (!eventListProp.length) return null;

            return {
                listings: eventListProp,
                index: category + listingType + index,
                category,
                showKeywords,
                listingType,
            };
        });
    }

    if (isSkuTypeAndKeywords) {
        return allCategories.map(({ title: category, listingType }, index) => {
            const eventListProp = getEventListingsOfCategory(
                category,
                categoriseType,
                eventListings
            );

            if (!eventListProp.length) return null;

            return {
                key: category,
                listings: eventListProp,
                index: category + listingType + index,
                showKeywords: null,
                category,
                listingType,
            };
        });
    }

    if (isNoCategorisation) {
        if (!eventListings.length) return null;

        return {
            listings: eventListings,
            showKeywords: null,
        };
    }

    return null;
};
