/* eslint-disable jsx-a11y/anchor-has-content */
import {
    ROUTES,
    SECTION_IDS,
    isBrowser,
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    SELECTORS,
} from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React, { useContext, useEffect, useState } from "react";
import { ITemplate } from "@Templates/ITemplate";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { IHost } from "@Interfaces";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "@Modules/hostPage/CreatorMiscellaneousData/data";
import { ICreatorMiscellaneousData } from "@Modules/hostPage/CreatorMiscellaneousData/ICreatorMiscellaneousData";
import { useSelector } from "react-redux";
import { logError } from "repoV2/utils/common/error/error";
import { MiscellaneousDataContext } from "@Pages/host";
import PictureCard from "../PictureCard";
import styles from "./blogPostsSection.module.scss";
import commonStyles from "../../../yoga/root/yoga.module.scss";
import SectionHeader from "../SectionHeader";

export const BlogPostsSection = ({
    title,
    blogPosts,
    preview,
    handlePreviewPopup,
}: ITemplate.IBlogPostsSectionProps) => {
    const { theme } = useUnlockPageContext();
    const hostName = useSelector(SELECTORS.hostName)!;

    const [blogsMisscellaneous, setBlogsMisscellaneous] = useState<
        ICreatorMiscellaneousData.IProps["blogs"] | undefined
    >(undefined);

    const miscellaneousPreviewData = useContext(
        MiscellaneousDataContext
    ) as unknown as {
        blogs: [{ data: ICreatorMiscellaneousData.IProps["blogs"] }];
    };

    useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.BLOGS,
                    }),
                });
                if (responseData.status === 200) {
                    const blogsSection =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.BLOGS
                        );

                    setBlogsMisscellaneous({
                        section_name: blogsSection?.data?.section_name,
                        data: blogsSection?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "Blogs Miscellaneous data",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    useEffect(() => {
        const blogs = miscellaneousPreviewData?.blogs;
        if (blogs?.length) {
            const _blogs = blogs[0]?.data;
            setBlogsMisscellaneous({
                section_name: _blogs?.section_name,
                data: {
                    heading: _blogs?.data?.heading,
                    description: _blogs?.data?.description,
                },
            });
        }
    }, [miscellaneousPreviewData]);

    const cardClick = (blogPost: IHost.IBlogPost) => {
        const postRoute = `${ROUTES.BLOG_POST.urlPath}/${blogPost?.uuid}`;

        if (preview) {
            handlePreviewPopup?.();
            return;
        }
        if (isBrowser()) {
            window.location.href = `${postRoute}`;
        }
    };

    const handleShowMore = (e: { preventDefault: () => void }) => {
        if (preview) {
            handlePreviewPopup();
            e.preventDefault();
        }
    };

    if (blogPosts?.length === 0) return <></>;

    return (
        <div
            className={styles.root}
            style={{ backgroundColor: theme?.theme?.color2 }}
        >
            <a id={SECTION_IDS.blog} className={commonStyles.sectionAnchor} />
            <div className={classNames(styles.uniformWidth, styles.container)}>
                <SectionHeader
                    subtitle={blogsMisscellaneous?.section_name || "blogs"}
                    title={blogsMisscellaneous?.data?.heading || title}
                    description={blogsMisscellaneous?.data?.description}
                />
                <div className={styles.blogPostsContainer}>
                    {blogPosts.map(blogPost => (
                        <PictureCard
                            key={blogPost.uuid}
                            data={blogPost}
                            handleClick={() => cardClick(blogPost)}
                            color={theme?.textColor?.color2}
                        />
                    ))}
                </div>
                <a
                    href={ROUTES.BLOG.urlPath}
                    className={styles.blogLink}
                    onClick={handleShowMore}
                    style={{ color: theme?.textColor?.color1 }}
                >
                    View More Blog Posts
                </a>
            </div>
        </div>
    );
};
