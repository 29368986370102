import React, { useContext, useEffect, useState, useRef } from "react";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { ApiCall, API_ACTIONS, API_ACTION_TYPES, MEDIA } from "@Utils";
import { SELECTORS } from "@Redux";
import { useSelector } from "react-redux";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "@Modules/hostPage/CreatorMiscellaneousData/data";
import { logError } from "repoV2/utils/common/error/error";
import { parseYTUrl } from "repoV2/utils/urls&routing/urls";
import { ICreatorMiscellaneousData } from "@Modules/hostPage/CreatorMiscellaneousData/ICreatorMiscellaneousData";
import { ITemplate } from "@Templates/ITemplate";
import dynamic from "next/dynamic";
import { MiscellaneousDataContext } from "@Pages/host";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { preview_empty_video_url } from "./constant";
import styles from "./introVideoSection.module.scss";

const VideoPlayer = dynamic(
    () => import("repoV2/components/common/VideoPlayer/VideoPlayer")
);

const IntroVideoSection = ({
    preview,
}: ITemplate.IAboutSectionProps): JSX.Element => {
    const { theme } = useUnlockPageContext();
    const hostName = useSelector(SELECTORS.hostName)!;
    const videoRef = useRef(null);

    const [introData, setIntroData] =
        useState<ICreatorMiscellaneousData.IProps["intro_video"]>();

    const miscellaneousPreviewData = useContext(
        MiscellaneousDataContext
    ) as unknown as {
        introVideo: [
            {
                data: ICreatorMiscellaneousData.IProps["intro_video"];
            }
        ];
    };

    useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.INTRO_VIDEO,
                    }),
                });
                if (responseData.status === 200) {
                    const data =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.INTRO_VIDEO
                        );

                    setIntroData({
                        section_name: data?.data?.section_name,
                        data: data?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "Intro Video",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    useEffect(() => {
        const introVideo = miscellaneousPreviewData?.introVideo;
        if (introVideo?.length) {
            const _introData = introVideo[0]?.data;
            setIntroData({
                section_name: _introData?.section_name,
                data: {
                    heading: _introData?.data?.heading,
                    description: _introData?.data?.description,
                    mediaLink: _introData?.data?.mediaLink,
                    intro_video_bg: _introData?.data?.intro_video_bg,
                },
            });
        }
    }, [miscellaneousPreviewData]);

    if (!introData?.data?.mediaLink && !preview) return <></>;

    return (
        <div
            className={styles.introVideoBg}
            style={{
                backgroundImage: `url(${getOptimisedImageUrl({
                    imageUrl:
                        introData?.data?.intro_video_bg ||
                        MEDIA.DEFAULT.BACKGROUND.UNLOCK.INTRO_VIDEO,
                    fetchWidth: 1500,
                })})`,
            }}
        >
            <div className={styles.introVideoRoot}>
                <div className={styles.introVideoContainer}>
                    <div className={styles.textContainer}>
                        <div
                            className={styles.sectionName}
                            style={{ color: theme?.textColor?.color1 }}
                        >
                            {introData?.section_name || "Know us better"}
                        </div>
                        <div
                            className={styles.heading}
                            style={{ color: theme?.textColor?.color2 }}
                        >
                            {introData?.data?.heading}
                        </div>
                        <div
                            className={styles.description}
                            style={{ color: theme?.textColor?.color3 }}
                        >
                            {introData?.data?.description}
                        </div>
                    </div>
                    <div className={styles.videoContainer}>
                        <div className={styles.videoPlayer}>
                            <VideoPlayer
                                key={introData?.data?.mediaLink}
                                ref={videoRef}
                                videoUrl={
                                    introData?.data?.mediaLink
                                        ? parseYTUrl(introData?.data?.mediaLink)
                                        : preview_empty_video_url
                                }
                                controls
                                // Setting height and width inherit, giving styling inherit of container div
                                // as video-player component does not take styling from css class
                                width="inherit"
                                height="inherit"
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: "nodownload",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroVideoSection;
