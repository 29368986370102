import React, { useState } from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { Skeleton } from "@mui/material";
import NextImage, {
    ImageLoaderProps as NextImageLoaderProps,
} from "next/image";
import { IImage } from "./Image";
import styles from "./Image.module.scss";

// Whether the image URL is included in the list of optimised domains
const isOptimisableImage = (imgUrl: IImage["src"]) => {
    /**
     * This should match the nextConfig.images.domains array
     */
    const OPTIMISED_DOMAINS = [
        "firebasestorage.googleapis.com",
        "images-na.ssl-images-amazon.com",
        "i1.sndcdn.com",
        "static.wikia.nocookie.net",
    ];

    return (
        // TODO: Parse the URL properly and then check
        imgUrl &&
        typeof imgUrl === "string" &&
        OPTIMISED_DOMAINS.some(optimisedDomain =>
            imgUrl.startsWith(optimisedDomain)
        )
    );
};

/**
 * why is this needed - https://stackoverflow.com/a/72152482
 *
 * Documnetation of this function - https://nextjs.org/docs/api-reference/next/image#loader
 */
const srcLoader = ({ src }: NextImageLoaderProps) => {
    return src;
};

/**
 * - `extraProps` can have
 *   - `<img>` attributes
 *   - [next/image props](https://nextjs.org/docs/api-reference/next/image)
 *
 *  Caveats:
 * - Provide width and height beforehand as props to use next/image.\
 *   - `next/image` acts weirdly otherwise(even with layout = "fill").\
 *   - If you would still like to override this functionality, use `forciblyUseNextImage = true`
 * - loader functionality needs width and height to show shimmer
 * - `style`: Use inline styles with care as next/image nests the `<img>` tag inside other tags.
 *      Instead use `className`
 * - if `<Image>` is inside an HTML tag with `display: flex`, for `height` and `width` props
 *      to work properly for optimisable as well as non-optimisable `src`:
 *      - pass `layout="fixed"` to `<Image>`
 *      - put `<Image>` inside a <div>
 */
/**
 * There are several issues in this regarding loading state causing permanent shimmer
 * to be shown in place of rendering the image
 * @deprecated Please do not use this Image component. For now instead use either
 * native HTML img tag or use 'next/image'.
 * Todo: Make a V2 of this component
 */
export const Image = ({
    src,
    alt,
    width,
    height,
    forciblyUseNextImage,
    disableShimmerFunctionality,
    loading,
    onLoad,
    ...extraProps
}: IImage) => {
    const hasHeightAndWidth = height && width; // loader will only work is height and width is provided

    const enableShimmerFunctionality =
        !!hasHeightAndWidth && !disableShimmerFunctionality;
    const [imgLoaded, setImgLoaded] = useState(false);

    if (!src) return null;

    const imageAndShimmerCommonProps = {
        width,
        height,
    };

    const imageCommonProps = {
        ...imageAndShimmerCommonProps,
        ...extraProps,
        onLoad: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            setImgLoaded(true);
            onLoad?.(e);
        },
        loading: loading || "lazy",
    };
    const image =
        isOptimisableImage(src as string) &&
        (forciblyUseNextImage || hasHeightAndWidth) ? (
            <NextImage
                src={src}
                alt={alt} // alt throws ts error when put in common props
                loader={srcLoader}
                {...imageCommonProps}
            />
        ) : (
            <img
                src={src as string | undefined}
                alt={alt} // alt throws ts error when put in common props
                {...imageCommonProps}
                className={classNames(
                    enableShimmerFunctionality && !imgLoaded
                        ? styles.loadingImage
                        : "",
                    imageCommonProps.className
                )}
            />
        );

    return (
        <>
            {image}
            {enableShimmerFunctionality && !imgLoaded ? (
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    {...imageAndShimmerCommonProps}
                />
            ) : null}
        </>
    );
};
