import React, { FC } from "react";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "./sectionHeader.module.scss";
import { SCROLLING_DIV_ID } from "../EventSections/eventsSections.constant";

const SectionHeader: FC<{
    title: string;
    subtitle: string;
    description?: string;
    descriptionClass?: string;
    rootClass?: string;
}> = props => {
    const { title, subtitle, description, descriptionClass, rootClass } = props;
    const { theme } = useUnlockPageContext();

    return (
        <div className={classNames(styles.container, rootClass && rootClass)}>
            {subtitle ? (
                <div
                    style={{ color: theme?.textColor?.color1 }}
                    className={styles.subtitle}
                >
                    {subtitle}
                </div>
            ) : null}
            {title ? (
                <div
                    style={{ color: theme?.textColor?.color2 }}
                    className={styles.heading}
                >
                    {title}
                </div>
            ) : null}
            {description ? (
                <div
                    style={{ color: theme?.textColor?.color3 }}
                    className={classNames(
                        styles.description,
                        descriptionClass && descriptionClass
                    )}
                >
                    {description}
                </div>
            ) : null}
            <div id={SCROLLING_DIV_ID} className={styles.scrollingDiv} />
        </div>
    );
};

export default SectionHeader;
