import React, { useState, useEffect, useRef } from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./index.module.scss";
import { ITabs } from "./Tabs.interfaces";
import {
    DEFAULT_SCROLL_DISTANCE,
    LEFT_TAB_SCROLL_STEP,
    RIGHT_TAB_SCROLL_STEP,
    TAB_SCROLL_SPEED,
} from "./Tabs.constant";

const Tabs = ({
    currentTab,
    tabsTitles,
    overRideSx,
    handleOnChange,
    classes,
}: ITabs.ITabsProps) => {
    const { tabSx } = overRideSx;

    const [showButtons, setShowButtons] = useState(false);

    const tabsContainerRef = useRef<any>(null);

    const titles = tabsTitles?.map(
        (props: { title: string; value: number }) => (
            <div
                id={`tabs-${props.value}`}
                style={{
                    ...tabSx?.root,
                    ...(props.value === currentTab && {
                        ...tabSx?.selected,
                    }),
                }}
                className={styles.tabStyle}
                key={props.title}
                onClick={e => handleOnChange(e, props.value)}
            >
                {props.title}
            </div>
        )
    );

    const checkShowButtons = () => {
        if (
            tabsContainerRef.current?.scrollWidth >
            tabsContainerRef.current?.clientWidth
        ) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    };

    useEffect(() => {
        if (tabsContainerRef.current) {
            const currentSelectedElement = document.getElementById(
                `tabs-${currentTab}`
            );
            if (currentSelectedElement) {
                checkShowButtons();
                window.addEventListener("resize", checkShowButtons);
            }
        }

        return () => {
            window.removeEventListener("resize", checkShowButtons);
        };
    }, [tabsContainerRef.current, currentTab]);

    const handleScroll = (
        scrollElement: HTMLElement | null,
        speed: number,
        step: number
    ): void => {
        if (!scrollElement) return;
        let scrollAmount = 0;
        const sliderTimer = setInterval(() => {
            const distance = tabsTitles?.length
                ? scrollElement?.scrollWidth / tabsTitles?.length
                : DEFAULT_SCROLL_DISTANCE;
            // eslint-disable-next-line no-param-reassign
            scrollElement.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(sliderTimer);
            }
        }, speed);
    };

    return (
        <div className={classNames(styles.tabsRoot, classes?.root)}>
            {showButtons && (
                <ChevronLeftIcon
                    className={styles.previous}
                    onClick={() =>
                        handleScroll(
                            tabsContainerRef.current,
                            TAB_SCROLL_SPEED,
                            LEFT_TAB_SCROLL_STEP
                        )
                    }
                />
            )}
            <div
                className={classNames(styles.tabsContainer)}
                ref={tabsContainerRef}
            >
                {titles}
            </div>
            {showButtons && (
                <ChevronRightIcon
                    className={styles.next}
                    onClick={() =>
                        handleScroll(
                            tabsContainerRef.current,
                            TAB_SCROLL_SPEED,
                            RIGHT_TAB_SCROLL_STEP
                        )
                    }
                />
            )}
        </div>
    );
};

export default Tabs;
