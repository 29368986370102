import { INavbar } from "@Modules/common/Navbar/INavbar";

export const showNavItems = [
    "team",
    "testimonials",
    "gallery",
    "blog",
] as Array<INavbar.INavbarItemKeys>;

export const constants = {
    showNavItems,
};

export const blue_theme_plan_type = 12;
