import React from "react";
import { VideoPlayer } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { trimText } from "repoV2/utils/common/dataTypes/string";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./portfolioCard.module.scss";

const UnlockPortfolioCard = (props: ITemplate.IPortfolioCard): JSX.Element => {
    const { data, onClickMediaCard, color } = props;
    const { theme } = useUnlockPageContext();

    const mediaJSX = (() => {
        if (data.media) {
            switch (data.type) {
                case "video": {
                    return (
                        <div className={styles.video}>
                            <VideoPlayer
                                videoUrl={data.media}
                                controls
                                optimizeUsingThumbnail
                                light
                                width="100%"
                                height="100%"
                            />
                        </div>
                    );
                }
                case "image":
                    return (
                        <div className={styles.image}>
                            <ExlyImage
                                fetchWidth={500}
                                src={data.media}
                                loading="lazy"
                                alt="portfolio"
                            />
                        </div>
                    );
                default:
            }
        }
        return null;
    })();

    return (
        <div
            style={{ backgroundColor: theme?.theme?.color1 }}
            className={styles.portfolioCard}
        >
            <div
                role="presentation"
                className={styles.portfolioImgContainer}
                onClick={onClickMediaCard}
            >
                {mediaJSX}
                <div style={{ color }} className={styles.cardDescription}>
                    {trimText(data.caption, 25)}
                </div>
            </div>
        </div>
    );
};

export default UnlockPortfolioCard;
