import { useUnlockPageContext } from "@Templates/unlock/root/context";
import React from "react";
import styles from "./styles.module.scss";

const RenderCustomDots = ({
    index,
    onClick,
    active,
}: {
    index: number;
    onClick: any;
    active: boolean;
}) => {
    const { theme } = useUnlockPageContext();

    return (
        <button
            key={index}
            onClick={onClick}
            className={styles.dot}
            style={{
                background: active ? theme?.textColor?.color1 : "",
            }}
            type="button"
            aria-label={`Dot ${index + 1}`}
        />
    );
};

export default RenderCustomDots;
