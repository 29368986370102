/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { SECTION_IDS } from "@Utils";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import styles from "./portfolio.module.scss";
import UnlockPortfolio from "./UnlockPortfolio";

const UnlockPortfolioSection = ({
    title,
    hideComponent,
    galleryData,
    onGalleryItemClick,
    description,
    pauseGalleryAutoplay,
}: ITemplate.IGallerySectionProps) => {
    const { theme } = useUnlockPageContext();
    if (hideComponent || galleryData.length < 1) return null;
    return (
        <div
            className={styles.portfolioRoot}
            style={{ background: theme?.theme?.color2 }}
        >
            <a id={SECTION_IDS.gallery} className={styles.sectionAnchor} />
            <div
                className={styles.portfolioTitle}
                style={{ backgroundColor: "#EBF4F8" }}
            >
                <div
                    className={styles.sectionTitle}
                    style={{ color: theme?.textColor?.color1 }}
                >
                    {title}
                </div>
                <div
                    className={styles.sectionHeading}
                    style={{ color: theme?.textColor?.color2 }}
                >
                    {description}
                </div>
            </div>
            <div className={styles.portfolioCardsContainer}>
                <UnlockPortfolio
                    galleryData={galleryData}
                    onGalleryItemClick={onGalleryItemClick}
                    color={theme?.textColor?.color2}
                    pauseGalleryAutoplay={pauseGalleryAutoplay}
                />
            </div>
        </div>
    );
};

export default UnlockPortfolioSection;
