import { useEffect } from "react";
import useMediaQuery from "@restart/hooks/cjs/useMediaQuery";
import { useSelector } from "react-redux";
import { SELECTORS } from "@Redux";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { IHost } from "@Interfaces";
import { INavbar } from "../../../../INavbar";
import styles from "../../navbarUnlock.module.scss";
import useNavigationTransition from "./useNavigationTransition";

const useUnlockNavbar = (props: INavbar.IChildProps) => {
    const {
        navbarItems,
        notificationProps,
        accountProps,
        hideMobHamMenu,
        onMobHamMenuClick,
        setIsMobHamMenuOpen,
        isMobHamMenuOpen,
        isOfferingsDropdownOpen,
        rootElement,
        templateRootRef,
    } = props;

    const isMobile: boolean = useMediaQuery("(max-width: 768px)");
    const { theme } = useUnlockPageContext();

    const { theme: otherTheme }: IHost.IStore = useSelector(SELECTORS.host);

    // If no value of theme present in context theme, use it from redux
    const unlockTheme = theme?.textColor?.color1
        ? theme
        : {
              textColor: {
                  color1: `rgb(${otherTheme?.colors["template-primary-color"]})`, // primary color
                  color2: `rgb(${otherTheme?.colors["template-title-color"]})`, // title color
                  color3: `rgb(${otherTheme?.colors["template-page-body-text"]})`, // body text color
              },
              theme: {
                  color1: `rgb(${otherTheme?.colors["template-page-background-primary"]})`, // primary background color
                  color2: `rgb(${otherTheme?.colors["template-page-background-secondary"]})`, // secondary background color
              },
          };

    const { scrolled, transition } = useNavigationTransition({
        ref: templateRootRef || null,
    });

    useEffect(() => {
        if (!isMobile) {
            setIsMobHamMenuOpen(false);
        }
    }, [isMobile]);

    const AccountAndNotificationProps = {
        notificationProps: {
            ...notificationProps,
            notificationIconColorScheme: styles.colourScheme1,
        },
        accountProps: {
            ...accountProps,
            accountIconColorScheme: styles.colourScheme1,
        },
    };

    return {
        theme: unlockTheme,
        AccountAndNotificationProps,
        navbarItems,
        hideMobHamMenu,
        onMobHamMenuClick,
        isMobHamMenuOpen,
        isOfferingsDropdownOpen,
        rootElement,
        isMobile,
        scrolled,
        transition,
    };
};

export default useUnlockNavbar;
