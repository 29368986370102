import React from "react";

const useMultiCarousal = (props: { totalItems: number }) => {
    const { totalItems } = props;
    const [activeSlide, setActiveSlide] = React.useState(1);

    // currentSlide, it is the slide that is active after slide change
    // Here note that react-multi-carousal starts index from 1 and keeps index 2 as the first active index, as it
    // keeps 1 slide before so that the reverse slide functionality always works
    // eg: List Items are - [a, b, c, d, e].
    // Now the ids assigned by the react-multi-carousal are [2, 3, 4, 5, 1 OR 6].
    // Carousel rendered is - [e(1), a(2), b(3), c(4), d(5), e(6)]... this is to support prev click (infinite loop)
    // Note: Give this function to the 'afterChange' prop of the Carousal component
    const handleAfterCarousalChange = (
        _: number,
        { currentSlide }: { currentSlide: number }
    ) => {
        // Incase user is back sliding, then the final slide can have index 1, so need to cater that
        if (currentSlide === 1) {
            setActiveSlide(totalItems);
        } else {
            // Beacuse the carousal keeps an extra slide, then active slide is 1 reduced form the
            // current slide it gives in callback
            setActiveSlide(currentSlide - 1);
        }
    };
    return { activeSlide, setActiveSlide, handleAfterCarousalChange };
};

export default useMultiCarousal;
