// TODO: Consider moving the business logic related to fetching and processing 'aboutUsData' outside the component.
// This can improve separation of concerns and make the component more focused on rendering.
// You may create a dedicated utility function or a hook to handle 'aboutUsData' operations.
// This will make the component cleaner and easier to maintain.
import React from "react";
import { FollowerCountIcons } from "@Modules/hostPage/FollowerCountIcons";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { classNames } from "repoV2/utils/common/render/classNames";
import { useIsDesktop } from "repoV2/utils/common/render/screen";
import { ITemplate } from "@Templates/ITemplate";
import { ISocialPlatformData } from "repoV2/utils/social/constants";
import styles from "./aboutCreator.module.scss";

const AboutCreator = ({
    socialLinks,
    aboutSectionTitle,
    description,
}: ITemplate.IAboutSectionProps): JSX.Element => {
    const { theme } = useUnlockPageContext();
    const isDesktop = useIsDesktop();

    const socialMediaIcons = (
        <div className={styles.iconContainer}>
            <FollowerCountIcons
                socialLinks={socialLinks}
                hideSocialIcons={false}
                containerClass={styles.followerCountContainer}
                itemClass={styles.followerCountMobileItem}
                socialIconCustomHandler={(socialObj: ISocialPlatformData) =>
                    socialObj.icon
                }
            />
        </div>
    );

    // `typeof aboutSectionTitle === "string" && !aboutSectionTitle` is only true if user has manually cleared this field.
    // This means that he does not want to show this field, hence we return nothing in this case
    if (
        typeof aboutSectionTitle === "string" &&
        !aboutSectionTitle &&
        !description
    )
        return <></>;

    return (
        <div
            style={{ background: theme?.theme?.color2 }}
            className={styles.creatorIntro}
        >
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.aboutWrapper}>
                        <div
                            className={classNames(
                                styles.lineContainer,
                                "d-flex align-items-center"
                            )}
                        >
                            <h2
                                style={{ color: theme?.textColor?.color2 }}
                                className={styles.aboutTitle}
                            >
                                {typeof aboutSectionTitle === "string"
                                    ? aboutSectionTitle
                                    : "About Us"}
                            </h2>
                            <div
                                style={{ color: theme?.textColor?.color2 }}
                                className={styles.aboutLine}
                            />
                        </div>
                        {isDesktop && socialLinks ? socialMediaIcons : null}
                    </div>
                    {description && (
                        <div
                            style={{ color: theme?.textColor?.color2 }}
                            className={styles.aboutText}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    )}
                </div>
                {!isDesktop && socialLinks ? socialMediaIcons : null}
            </div>
        </div>
    );
};

AboutCreator.propTypes = {};

export default AboutCreator;
