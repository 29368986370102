import React, { useEffect } from "react";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { ROUTES } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { BsCaretDownFill as DownIcon } from "@react-icons/all-files/bs/BsCaretDownFill";
import { BsCaretUpFill as UpIcon } from "@react-icons/all-files/bs/BsCaretUpFill";
import { useRouter } from "next/router";
import { PoweredByExly } from "repoV2/features/CreatorPages/modules/PoweredByExly";
import { LISTING_TYPES } from "repoV2/features/Listing/Listing.constants";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { isBrowser } from "repoV2/utils/common/render/window";
import styles from "./navbarUnlock.module.scss";
import { INavbar } from "../../INavbar";
import { NAVBAR_ITEM_KEYS } from "../../data/getNavbarItems";
import AccountAndNotification from "../../components/AccountsAndNotification";
import { NavLink, LinkItem } from "../../components/NavLinks";
import { blue_theme_plan_type, constants } from "./components/constants";
import useUnlockNavbar from "./components/hooks/useUnlockNavbar";

const NavbarUnlock = (props: INavbar.IChildProps): JSX.Element => {
    const {
        theme,
        AccountAndNotificationProps,
        navbarItems,
        hideMobHamMenu,
        onMobHamMenuClick,
        isMobHamMenuOpen,
        isOfferingsDropdownOpen,
        rootElement,
        isMobile,
        scrolled,
        transition,
    } = useUnlockNavbar(props);
    const { updateNavBarSelected } = useUnlockPageContext();
    const router = useRouter();

    // checking route to show scrolled style component of navbar
    const getUnlockScrolledNavbarByRoute = () => {
        if (
            [
                ROUTES.BLOG.urlRoute,
                ROUTES.MY_CLASSES.route,
                ROUTES.PURCHASE_HISTORY.route,
            ].some(path => (router?.pathname || "").includes(path))
        ) {
            return true;
        }
        return false;
    };

    // For blog page show scrolled navbar component.
    const isBlogPage = getUnlockScrolledNavbarByRoute();

    const showScrolledNavbar = scrolled || isBlogPage;

    const color1 = theme?.theme?.color1;
    const color2 = theme?.textColor?.color2;

    /**
     * initialFontColorDesktop represents the color used when page isn't scrolled
     */
    const initialFontColorDesktop = color1;
    /**
     * scrolledFontColorDesktop represents the color used when page is scrolled
     */
    const scrolledFontColorDesktop = color2;

    const textColor =
        showScrolledNavbar || isMobHamMenuOpen
            ? scrolledFontColorDesktop
            : initialFontColorDesktop;

    useEffect(() => {
        if (isBrowser()) {
            // dont scroll to the section with window.location.hash directly on mount
            if (window.location.hash) {
                window.scrollTo(0, 0);
            }
            // rather let the page load, using the timer
            // and then set necessary states and then redirect
            setTimeout(() => {
                let section = window.location.hash;
                if (section) {
                    section = section.replace("#", "");
                    const currentSelectedElement =
                        document.getElementById(section);
                    if (currentSelectedElement) {
                        currentSelectedElement.scrollIntoView({
                            inline: "center",
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                }
            }, 1200);
        }
    }, []);

    const onEventListingItemClick = (
        e: React.MouseEvent<HTMLDivElement>,
        navbarItem: INavbar.INavbarItem
    ) => {
        // Replaced anchor tag to div. Now the user will be redirected to section
        // using this custom onClick function instead of navbarItem.props.onClick.
        // otherwise, scroll functionallity of anchor tag does not work.
        e?.stopPropagation();

        // Incase of blue theme - unlock - the logic of navbar redirection incase of the
        // click of offerings is different, and for plans, the listing type is unlock specific,
        // thus this change is made here as navbarItem is similar for all templates
        // Without this, the user is not able to redirect to Plans from clicking on it on the Navbar
        const newUnlokNavbarValue =
            +navbarItem.props?.key! === LISTING_TYPES.PLANS
                ? blue_theme_plan_type
                : navbarItem.props.key;
        updateNavBarSelected(newUnlokNavbarValue);

        const section = `tabs-${newUnlokNavbarValue}`;

        // if clicked link path is not same as current path
        // redirect and dont execute the rest of the funcitonality
        if (router.pathname !== `/${ROUTES.HOST.filePath}`) {
            window.location.href = `${ROUTES.HOST.urlPath}#${section}`;
            return;
        }

        window.location.hash = section;

        const currentSelectedElement = document.getElementById(section);
        if (currentSelectedElement) {
            currentSelectedElement.scrollIntoView({
                inline: "center",
                behavior: "smooth",
                block: "center",
            });
        }
        if (isMobHamMenuOpen) {
            onMobHamMenuClick();
        }
    };

    const EventListingsDropdown = () => (
        <div
            className={classNames(
                styles.eventListingsDropdownContainer,
                navbarItems.eventListings.showChildren && styles.allowDropdown,
                isOfferingsDropdownOpen && styles.open
            )}
        >
            {navbarItems.eventListings.showChildren
                ? navbarItems.eventListings.children.map(navbarItem => (
                      <div
                          key={navbarItem.content.toString()}
                          className={classNames(
                              styles.navbarItem,
                              styles.eventListingsDropdownItem,
                              isOfferingsDropdownOpen && styles.open
                          )}
                          {...navbarItem.props}
                          onClick={e => onEventListingItemClick(e, navbarItem)}
                      >
                          {navbarItem.content}
                      </div>
                  ))
                : null}
        </div>
    );

    let color: string | undefined;
    if (isMobHamMenuOpen || showScrolledNavbar) {
        color = scrolledFontColorDesktop;
    } else {
        color = initialFontColorDesktop;
    }

    const DesktopMobCommonItems = () => {
        return (
            <>
                <NavLink
                    showItems={constants.showNavItems}
                    navbarItems={navbarItems}
                    styleProps={{
                        color,
                    }}
                    classes={styles.navbarItem}
                />

                {navbarItems.externalLinks.map(item => (
                    <LinkItem
                        // item.content is string for externalLinks
                        key={item.content.toString()}
                        content={item.content}
                        style={{
                            color,
                        }}
                        className={styles.navbarItem}
                        {...item.props}
                    />
                ))}
            </>
        );
    };

    const accountAndNotification = (
        <div
            style={{
                color,
            }}
            className={styles.accountAndNotificationContainer}
        >
            <AccountAndNotification {...AccountAndNotificationProps} />
        </div>
    );

    return (
        <div
            style={{
                background:
                    showScrolledNavbar || isMobHamMenuOpen
                        ? theme?.theme?.color1
                        : "",
            }}
            className={classNames(
                styles.root,
                scrolled && !isBlogPage && !transition && styles.rootScrolling,
                showScrolledNavbar && styles.rootScrolled
            )}
            ref={rootElement}
        >
            <link
                href="https://fonts.googleapis.com/css2?family=Kalam:wght@400&display=swap"
                rel="stylesheet"
                as="font"
                crossOrigin="anonymous"
            />
            <a
                style={{
                    color: textColor,
                }}
                className={styles.logo}
                {...navbarItems.home.props}
            >
                {navbarItems.home.content}
            </a>
            <div className={classNames(styles.desktop, styles.rightContainer)}>
                <div className={styles.centerContainer}>
                    <a
                        style={{
                            color: textColor,
                        }}
                        className={styles.navbarItem}
                        {...navbarItems.about.props}
                    >
                        {navbarItems.about.content}
                    </a>
                    {navbarItems.eventListings.showItem ? (
                        <div
                            style={{
                                color: textColor,
                            }}
                            className={classNames(
                                styles.navbarItem,
                                styles.eventListingsButton,
                                navbarItems.eventListings.showChildren &&
                                    styles.allowDropdown
                            )}
                            {...navbarItems.eventListings.props}
                        >
                            {navbarItems.eventListings.content}
                            <EventListingsDropdown />
                        </div>
                    ) : null}

                    <DesktopMobCommonItems />
                </div>
            </div>
            <div className={classNames(styles.desktop, styles.buttonContainer)}>
                {navbarItems.login.showItem ? (
                    <a
                        style={{
                            color: textColor,
                        }}
                        className={styles.navbarButton}
                        {...navbarItems.login.props}
                    >
                        {navbarItems.login.content}
                    </a>
                ) : null}
                {navbarItems.getInTouch.showItem ? (
                    <div
                        style={{
                            color: textColor,
                        }}
                        className={styles.navbarButton}
                        {...navbarItems.getInTouch.props}
                    >
                        {navbarItems.getInTouch.content}
                    </div>
                ) : null}
                {accountAndNotification}
            </div>
            <div className={classNames(styles.mobile, styles.rightContainer)}>
                {isMobHamMenuOpen ? null : accountAndNotification}
                {isMobile ? (
                    <>
                        {!hideMobHamMenu ? (
                            <div
                                style={{
                                    color: textColor,
                                }}
                                className={classNames(styles.button)}
                                onClick={onMobHamMenuClick}
                            >
                                <GiHamburgerMenu />
                            </div>
                        ) : null}
                    </>
                ) : (
                    <>
                        {!hideMobHamMenu ? (
                            <div
                                style={{
                                    color: textColor,
                                }}
                                className={classNames(
                                    styles.mobHamMenuButton,
                                    styles.colourScheme2
                                )}
                                onClick={onMobHamMenuClick}
                            >
                                <span>
                                    {isMobHamMenuOpen ? "Close" : "Menu"}
                                </span>
                            </div>
                        ) : null}
                    </>
                )}
            </div>
            <div
                style={{ backgroundColor: theme?.theme?.color1 }}
                className={classNames(
                    styles.mobile,
                    styles.mobileRoot,
                    isMobHamMenuOpen && styles.open
                )}
            >
                <a
                    style={{
                        color: color2,
                    }}
                    className={styles.navbarItem}
                    {...navbarItems.about.props}
                >
                    {navbarItems.about.content}
                </a>
                {navbarItems.eventListings.showItem ? (
                    <div
                        className={classNames(styles.navbarItem)}
                        {...navbarItems.eventListings.props}
                    >
                        <div
                            style={{
                                color: color2,
                            }}
                            className={classNames(styles.eventListingsButton)}
                        >
                            {navbarItems.eventListings.content}
                            {navbarItems.eventListings.showChildren ? (
                                <>
                                    &nbsp;&nbsp;
                                    {isOfferingsDropdownOpen ? (
                                        <UpIcon />
                                    ) : (
                                        <DownIcon />
                                    )}
                                </>
                            ) : null}
                        </div>
                        <EventListingsDropdown />
                    </div>
                ) : null}
                <DesktopMobCommonItems />
                <hr />
                {(
                    [
                        NAVBAR_ITEM_KEYS.myClasses,
                        NAVBAR_ITEM_KEYS.myMeetings,
                        NAVBAR_ITEM_KEYS.purchaseHistory,
                    ] as Array<INavbar.INavbarItemKeys>
                ).map(navbarItemKey => {
                    const navbarItem = navbarItems[navbarItemKey];
                    return navbarItem.showItem ? (
                        <a
                            key={navbarItemKey}
                            className={styles.navbarItem}
                            {...navbarItem.props}
                        >
                            {navbarItem.content}
                        </a>
                    ) : null;
                })}
                {navbarItems.getInTouch.showItem ? (
                    <div
                        style={{
                            color: color2,
                        }}
                        className={classNames(
                            styles.colourScheme2,
                            styles.button
                        )}
                        {...navbarItems.getInTouch.props}
                    >
                        {navbarItems.getInTouch.content}
                    </div>
                ) : null}
                {(["login", "logout"] as Array<INavbar.INavbarItemKeys>).map(
                    navbarItemKey => {
                        const navbarItem = navbarItems[navbarItemKey];
                        return navbarItem.showItem ? (
                            <a
                                style={{
                                    color: color2,
                                }}
                                key={navbarItemKey}
                                className={classNames(
                                    styles.navButton,
                                    styles.button
                                )}
                                {...navbarItem.props}
                            >
                                {navbarItem.content}
                            </a>
                        ) : null;
                    }
                )}
                {/** TODO @aditya_gautam : remove if not in use after 28|02|2024.
                 * Also remove the inline styles in case you use it.
                 */}
                {/* <div style={{ flexGrow: 1 }}>
                    <div className={styles.eduverseArrow}>
                        <CurvedArrowSvg
                            className={styles.arrowIcon}
                            stroke={colorsConfig.scrolledFontColorDesktop}
                        />
                    </div>
                </div> */}
                <hr />
                <PoweredByExly showCopyright />
            </div>
        </div>
    );
};

export default NavbarUnlock;
