import React, { useRef, useState } from "react";
import { Navbar } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { FooterSection } from "repoV2/features/CreatorPages/modules/FooterSection";
import { useSelector } from "react-redux";
import { IHost } from "@Interfaces";
import { SELECTORS } from "@Redux";
import { DEFAULT_FONT } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import style from "./unlock.module.scss";
import AboutUs from "../components/AboutUs/index";
import TopFeaturesSection from "../components/TopFeaturesSection";
import StatsSection from "../components/StatsSection";
import { TestimonialsSection } from "../components/TestimonialsSection";
import { TeamSection } from "../components/TeamSection";
import { BlogPostsSection } from "../components/BlogPostsSection";
import { EventSections } from "../components/EventSections";
import UnlockPortfolioSection from "../components/Portfolio";
import AdditionalPointersSection from "../components/AdditionalPointers";
import IntroVideoSection from "../components/IntroVideoSection";
import FeaturedInSection from "../components/FeaturedIn";
import AboutCreator from "../components/AboutCreator";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";
import { UnlockPageContextProvider } from "./context";
import { IUnlockContextValue } from "./Unlock.interfaces";
import { initial_context_value } from "./Unlock.constants";

const Unlock = ({
    navbarProps,
    footerSectionProps,
    aboutSectionProps,
    eventSectionsProps,
    teamSectionProps,
    blogPostsSectionProps,
    testimonialsSectionProps,
    gallerySectionProps,
    topFeaturesProps,
    statsSectionProps,
}: ITemplate.IProps): JSX.Element => {
    const rootRef = useRef(null);
    const { theme, font }: IHost.IStore = useSelector(SELECTORS.host);

    const contextValue: IUnlockContextValue = {
        theme: {
            textColor: {
                color1: `rgb(${theme?.colors["template-primary-color"]})`, // primary color
                color2: `rgb(${theme?.colors["template-title-color"]})`, // title color
                color3: `rgb(${theme?.colors["template-page-body-text"]})`, // body text color
            },
            theme: {
                color1: `rgb(${theme?.colors["template-page-background-primary"]})`, // primary background color
                color2: `rgb(${theme?.colors["template-page-background-secondary"]})`, // secondary background color
            },
        },
        font,
        navBarSelected: undefined,
        updateNavBarSelected: undefined,
    };

    const [navbarSelected, setNavbarSelected] = useState(
        initial_context_value.navBarSelected
    );

    const updateNavBarSelected = (value: string | undefined) => {
        setNavbarSelected(value);
    };

    const rootStyle =
        font?.slug === DEFAULT_FONT?.slug
            ? classNames(style.root, style.custom_font_root)
            : style.root;

    return (
        <UnlockPageContextProvider
            value={{
                theme: contextValue.theme,
                font: contextValue.font,
                navBarSelected: navbarSelected,
                updateNavBarSelected,
            }}
        >
            <link
                href="https://fonts.googleapis.com/css2?family=Jost:wght@200;400;500&display=swap"
                rel="stylesheet"
                as="font"
                crossOrigin="anonymous"
            />
            <div ref={rootRef} className={rootStyle}>
                <Navbar templateRootRef={rootRef} {...navbarProps} />
                <AboutUs {...aboutSectionProps} />
                <CustomCodeSection
                    position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
                />
                <FeaturedInSection />
                <EventSections {...eventSectionsProps} />
                <IntroVideoSection {...aboutSectionProps} />
                <AboutCreator {...aboutSectionProps} />
                <TopFeaturesSection {...topFeaturesProps} />
                <AdditionalPointersSection />
                <TestimonialsSection {...testimonialsSectionProps} />
                <BlogPostsSection {...blogPostsSectionProps} />
                <TeamSection {...teamSectionProps} />
                <UnlockPortfolioSection {...gallerySectionProps} />
                <StatsSection {...statsSectionProps} />
                <CustomCodeSection
                    position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
                />
                <FooterSection {...footerSectionProps} />
            </div>
        </UnlockPageContextProvider>
    );
};

export default Unlock;
