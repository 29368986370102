import React from "react";
import {
    getMaxLengthBasedOnDeviceWidth,
    ImagePreviewIconSvg,
    TESTIMONIAL_DATA_TYPE,
    trimText,
} from "@Utils";
import { useIsDesktop } from "repoV2/utils/common/render/screen";
import { classNames } from "repoV2/utils/common/render/classNames";
import { ITemplate } from "@Templates/ITemplate";

import { VideoPlayer } from "@Modules/common";

import { ButtonV2 } from "@Modules/common/ButtonV2";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./testimonialCard.module.scss";

export const TestimonialCard = ({
    data,
    onTestimonialClick,
}: ITemplate.ITestimonialCardProps) => {
    const isDesktop = useIsDesktop();
    const { theme } = useUnlockPageContext();
    const TestimonialContent = () => {
        if (data?.type === TESTIMONIAL_DATA_TYPE.VIDEO) {
            return (
                <div
                    className={classNames(
                        styles.contentContainer,
                        styles.videoContainer
                    )}
                    onClick={onTestimonialClick}
                >
                    <VideoPlayer
                        videoUrl={data.media}
                        optimizeUsingThumbnail
                        light
                        width="100%"
                        height="100%"
                    />
                    <div className={styles.mediaOverlay} />
                </div>
            );
        }

        if (data?.type === TESTIMONIAL_DATA_TYPE.IMAGE) {
            return (
                <div
                    className={classNames(
                        styles.contentContainer,
                        styles.imageContainer
                    )}
                    onClick={onTestimonialClick}
                >
                    <ExlyImage
                        fetchWidth={500}
                        src={data.media}
                        className={styles.image}
                        alt={data?.title || "Testimonial image"}
                    />

                    <div className={styles.mediaOverlay}>
                        <ImagePreviewIconSvg height="40px" />
                    </div>
                </div>
            );
        }

        const maxLength = getMaxLengthBasedOnDeviceWidth([
            [780, 280],
            [420, 200],
            [360, 160],
        ]);

        const showReadMore: boolean = !!(data?.content?.length > maxLength);

        return (
            <div className={styles.contentContainer}>
                {data.content.substring(0, maxLength)}
                {showReadMore ? (
                    <>
                        ...&nbsp;
                        <ButtonV2
                            design="plain"
                            applyTheme
                            className={styles.readMore}
                            onClick={onTestimonialClick}
                        >
                            Read More
                        </ButtonV2>
                    </>
                ) : null}
            </div>
        );
    };

    return (
        <div
            className={styles.root}
            style={{
                background: isDesktop
                    ? theme?.theme?.color1
                    : `linear-gradient(to bottom, #EBF4F8 58%, ${theme?.theme?.color1} 40%)`,
            }}
        >
            <div className={classNames(styles.container)}>
                <div className={styles.authorContainer}>
                    <div className={styles.topText}>
                        <div
                            style={{ color: theme?.textColor?.color2 }}
                            className={styles.testimonialHeading}
                        >
                            {data?.title}
                        </div>
                        <div
                            className={styles.authorName}
                            style={{ color: theme?.textColor?.color1 }}
                        >
                            {trimText(data.name, 30)}
                        </div>
                    </div>
                    {isDesktop ? (
                        <ExlyImage
                            fetchWidth={150}
                            className={styles.authorImage}
                            src={data.cover_image}
                            alt="Testimonial image"
                        />
                    ) : null}
                </div>
                <TestimonialContent />
            </div>
        </div>
    );
};
