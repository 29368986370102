// TODO: Consider moving the business logic related to fetching and processing 'featuredIn' outside the component.
// This can improve separation of concerns and make the component more focused on rendering.
// You may create a dedicated utility function or a hook to handle 'featuredIn' operations.
// Additionally, search for other components in this template that may contain business logic and consider refactoring them for better maintainability.
import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useIsDesktop } from "repoV2/utils/common/render/screen";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { ApiCall, API_ACTIONS, API_ACTION_TYPES, SELECTORS } from "@Utils";
import { useSelector } from "react-redux";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "@Modules/hostPage/CreatorMiscellaneousData/data";
import { ICreatorMiscellaneousData } from "@Modules/hostPage/CreatorMiscellaneousData/ICreatorMiscellaneousData";
import { logError } from "repoV2/utils/common/error/error";
import { MiscellaneousDataContext } from "@Pages/host";
import { checkIsArrayEmpty } from "repoV2/utils/common/dataTypes/array";
import { FIRST_ELEMENT } from "@Templates/unlock/constants/constant";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./featuredIn.module.scss";

const FeaturedInSection = (): JSX.Element => {
    const { theme } = useUnlockPageContext();
    const hostName = useSelector(SELECTORS.hostName)!;
    const [featuredIn, setFeaturedIn] = useState<
        ICreatorMiscellaneousData.IProps["brand_collaborations"] | undefined
    >(undefined);
    const isMobile = !useIsDesktop();

    const miscellaneousPreviewData = useContext(
        MiscellaneousDataContext
    ) as unknown as {
        brands: [
            { data: ICreatorMiscellaneousData.IProps["brand_collaborations"] }
        ];
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1301 },
            items: 6,
        },
        tablet: {
            breakpoint: { max: 1300, min: 901 },
            items: 4,
        },
        mobile: {
            breakpoint: { max: 900, min: 0 },
            items: 4,
        },
    };

    useEffect(() => {
        const brands = miscellaneousPreviewData?.brands;
        if (brands?.length) {
            const _brands = brands[FIRST_ELEMENT].data;

            setFeaturedIn({
                section_name: _brands?.section_name,
                data: _brands?.data,
            });
        }
    }, [miscellaneousPreviewData]);

    useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.BRAND_COLLABORATION,
                    }),
                });
                if (responseData.status === 200) {
                    const brandsData =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.BRAND_COLLABORATION
                        );

                    setFeaturedIn({
                        section_name: brandsData?.data?.section_name,
                        data: brandsData?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "BrandsList",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);
    if (checkIsArrayEmpty(featuredIn?.data)) {
        return <></>;
    }
    const featuredInElements = featuredIn?.data?.map(feature => (
        <div className={styles.brandSection} key={feature?.brand_name}>
            {feature?.brand_url ? (
                <a
                    href={feature.brand_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.imageContainer}
                >
                    <ExlyImage
                        fetchWidth={250}
                        src={feature.brand_logo}
                        className={styles.imgClass}
                        alt={feature.brand_name || "feature"}
                    />
                </a>
            ) : (
                <ExlyImage
                    fetchWidth={250}
                    src={feature.brand_logo}
                    className={styles.imgClass}
                    alt={feature.brand_name || "feature"}
                />
            )}
            {feature?.brand_name && (
                <div className={styles.brandName}>{feature.brand_name}</div>
            )}
        </div>
    ));

    return (
        <div style={{ backgroundColor: theme?.theme?.color1 }}>
            <div className={styles.featuredInSectionContainer}>
                {featuredIn?.section_name ? (
                    <div
                        style={{ color: theme?.textColor?.color2 }}
                        className={styles.featuredInTitle}
                    >
                        {featuredIn.section_name}
                    </div>
                ) : (
                    <></>
                )}
                {isMobile ? (
                    <div className={styles.featureListContainer}>
                        {featuredInElements}
                    </div>
                ) : (
                    <div className={styles.gd_carousel_wrapper}>
                        <Carousel
                            responsive={responsive}
                            infinite
                            arrows={false}
                            autoPlay
                            showDots={false}
                            pauseOnHover
                            keyBoardControl
                        >
                            {featuredInElements}
                        </Carousel>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FeaturedInSection;
