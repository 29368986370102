import React, { useContext, useEffect, useState } from "react";
import VerticalImageSlider from "@Templates/unlock/components/AdditionalPointers/components/VerticalImageSlider";
import { ApiCall, API_ACTIONS, API_ACTION_TYPES, SELECTORS } from "@Utils";
import { useSelector } from "react-redux";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "@Modules/hostPage/CreatorMiscellaneousData/data";
import { ICreatorMiscellaneousData } from "@Modules/hostPage/CreatorMiscellaneousData/ICreatorMiscellaneousData";
import { logError } from "repoV2/utils/common/error/error";
import { MiscellaneousDataContext } from "@Pages/host";

const AdditionalPointersSection = () => {
    const hostName = useSelector(SELECTORS.hostName)!;
    const [additionalPointers, setAdditionalPointers] = useState<
        [ICreatorMiscellaneousData.IProps["additional_pointers"]] | undefined
    >(undefined);

    const miscellaneousPreviewData = useContext(
        MiscellaneousDataContext
    ) as unknown as {
        additionalPointers: [
            {
                section_name: string;
                data: {
                    section_name: string;
                    section_heading: string;
                    data: any;
                };
            }
        ];
    };

    useEffect(() => {
        const additionalPointer = miscellaneousPreviewData?.additionalPointers;
        if (additionalPointer?.length) {
            const [_additionalPointers] = additionalPointer;

            const { data, section_name, section_heading } =
                _additionalPointers?.data;

            const processedData = data?.map(
                (item: {
                    additional_pointers_cover: any;
                    title: any;
                    description: any;
                }) => ({
                    sectionTitle: section_name,
                    sectionDescription: section_heading,
                    coverImage: item?.additional_pointers_cover,
                    link: {
                        title: item.title,
                        description: item.description,
                    },
                })
            );
            setAdditionalPointers(processedData);
        }
    }, [miscellaneousPreviewData]);

    useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.ADDITIONAL_POINTERS,
                    }),
                });
                if (responseData.status === 200) {
                    const response =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.ADDITIONAL_POINTERS
                        );

                    const processedData = response?.data?.data.map(
                        (item: {
                            additional_pointers_cover: any;
                            title: any;
                            description: any;
                        }) => ({
                            sectionTitle: response?.data?.section_name,
                            sectionDescription: response?.data?.section_heading,
                            coverImage: item?.additional_pointers_cover,
                            link: {
                                title: item.title,
                                description: item.description,
                            },
                        })
                    );

                    setAdditionalPointers(processedData);
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "BrandsList",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    if (!additionalPointers?.length) return <></>;

    return additionalPointers ? (
        <div style={{ backgroundColor: "#EBF4F8" }}>
            <VerticalImageSlider data={additionalPointers} />
        </div>
    ) : null;
};

export default AdditionalPointersSection;
