import { useEffect, useState } from "react";
import useOnScroll from "@Utils/hooks/useOnScroll";

const useNavigationTransition = ({
    ref,
}: {
    ref: null | React.RefObject<HTMLDivElement>;
}) => {
    const [transition, setTransition] = useState(false);
    const { reached: scrolled } = useOnScroll({
        scrollHeight: 40,
        ref: ref || null,
    });

    useEffect(() => {
        let _interval: NodeJS.Timeout;
        if (scrolled && !transition) {
            setTransition(false);
            _interval = setTimeout(() => {
                setTransition(true);
            }, 100);
        } else {
            setTransition(false);
        }

        return () => clearInterval(_interval);
    }, [scrolled]);

    return {
        scrolled,
        transition,
    };
};

export default useNavigationTransition;
