import { trimText } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { FolderSvg } from "@Utils/assets/FolderSvg";
import { Image } from "repoV2/components/common/Image";
import { parseDateTime } from "@Utils/parseDateTime";
import styles from "./PictureCard.module.scss";
import { IPictureCard } from "./IPictureCard";

const PictureCard = ({ data, className, handleClick, color }: IPictureCard) => {
    const { theme } = useUnlockPageContext();

    return (
        <div
            style={{ backgroundColor: theme?.theme?.color1 }}
            className={classNames(styles.container, className)}
        >
            <div className={styles.cardButton} onClick={handleClick}>
                {data.thumbnail_image ? (
                    <Image
                        className={styles.image}
                        src={data.thumbnail_image}
                        alt={data.title || ""}
                    />
                ) : null}
                <div className={styles.infoContainer}>
                    <div
                        className={styles.timeInfo}
                        style={{ color: theme?.textColor?.color2 }}
                    >
                        <FolderSvg />
                        {data.created_at
                            ? parseDateTime(data.created_at, "MMM dd, yyyy")
                            : null}
                    </div>
                    <div
                        style={{ color }}
                        className={classNames(styles.cardTitle)}
                    >
                        {trimText(data.title || "", 30)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PictureCard;
