// TODO: Simplify the logic in this component. Currently, 'tabsContent' is not utilized in the Tablet component
// and the component mainly handles tab selection.
// Consider refactoring to remove unnecessary code and improve component readability.
import React, { useEffect, useState } from "react";
import TabsHeader from "./components/TabsHeader";
import { ITablet } from "./Tablet.interfaces";

const initialValue = 0;

const Tablet = (props: ITablet.ITabletProps) => {
    const {
        data: { tabsTitles, tabsContent },
        overRideSx,
        cb,
        selection,
    } = props;
    const [currentTab, setCurrentTab] = useState<number>(
        selection || initialValue
    );

    useEffect(() => {
        setCurrentTab(selection);
    }, [selection]);

    const handleOnChange = (
        event: any,
        value: React.SetStateAction<number>
    ) => {
        setCurrentTab(value);
        if (typeof cb === "function") cb(value);
    };

    return (
        <>
            <TabsHeader
                currentTab={currentTab}
                tabsTitles={tabsTitles}
                overRideSx={overRideSx}
                handleOnChange={handleOnChange}
            />
            {tabsContent[currentTab]}
        </>
    );
};

export default Tablet;
