import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import styles from "./teamMemberCard.module.scss";

export const TeamMemberCard = ({
    data,
    cardClick,
}: ITemplate.ITeamMemberCardProps) => {
    const { theme } = useUnlockPageContext();
    return (
        <div className={styles.root} onClick={() => cardClick(data)}>
            <div className={styles.imageRing}>
                <img
                    className={styles.image}
                    src={data.displayImage}
                    alt="Card"
                />
            </div>
            <div className={styles.cardBody}>
                <div
                    className={styles.cardName}
                    style={{ color: theme?.textColor?.color2 }}
                >
                    {data.memberName.slice(0, 21)}
                </div>
                <div
                    style={{ color: theme?.textColor?.color3 }}
                    className={styles.cardtitle}
                >
                    {data.staffHeading.slice(0, 60)}
                </div>
            </div>
        </div>
    );
};
