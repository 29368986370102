import React, { createContext, useContext } from "react";
import { IUnlockContextValue } from "./Unlock.interfaces";
import { initial_context_value } from "./Unlock.constants";

export const UnlockPageContext = createContext(initial_context_value);

export const UnlockPageContextProvider = ({
    value,
    children,
}: {
    value: IUnlockContextValue;
    children: React.ReactNode;
}) => (
    <UnlockPageContext.Provider value={value}>
        {children}
    </UnlockPageContext.Provider>
);

export const useUnlockPageContext = () => useContext(UnlockPageContext);
