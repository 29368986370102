import { ITemplate } from "@Templates/ITemplate";
import { EVENT_ENUM, MEDIA } from "@Utils";
import { trimText } from "repoV2/utils/common/dataTypes/string";

const useOfferingCard = ({
    data,
    onEventListingClick,
    showSkuLabelAsTag,
}: {
    data: ITemplate.IEventListingData;
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
    showSkuLabelAsTag?: boolean;
}) => {
    const metadata = data?.metadata;

    const handleOfferingClick = () => onEventListingClick(data);

    const hasDiscountPrice =
        data?.price_per_head &&
        data?.updated_price &&
        data?.price_per_head !== data?.updated_price;
    const price = hasDiscountPrice ? data.price_per_head : data.updated_price;

    return {
        handleOfferingClick,
        cover_image: data.cover_image || MEDIA.DEFAULT.CARD_IMAGE,
        sku_type: showSkuLabelAsTag ? EVENT_ENUM[data.type].TITLE : null,
        listing_title: trimText(data.title, 65),
        hide_price: data.hidePrice,
        price: `${data?.currency} ${Math.round(price || 0)}`,
        hide_schedule: !metadata?.hide_schedule,
        description: trimText(data.short_description, 100),
        cta_text: data.ctaText,
        updated_price: `${data?.currency} ${Math.round(data.updated_price)}`,
        hasDiscountPrice,
    };
};

export default useOfferingCard;
