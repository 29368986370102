/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useEffect, useState } from "react";
import { ITemplate } from "@Templates/ITemplate";
import {
    SECTION_IDS,
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    SELECTORS,
} from "@Utils";
import { useIsDesktop } from "repoV2/utils/common/render/screen";
import Carousel from "react-multi-carousel";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { useSelector } from "react-redux";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "@Modules/hostPage/CreatorMiscellaneousData/data";
import { ICreatorMiscellaneousData } from "@Modules/hostPage/CreatorMiscellaneousData/ICreatorMiscellaneousData";
import { logError } from "repoV2/utils/common/error/error";
import { MiscellaneousDataContext } from "@Pages/host";
import { TeamMemberCard } from "./TeamMemberCard";
import styles from "./teamSection.module.scss";
import SectionHeader from "../SectionHeader";
import CarouselCustomDots from "../CarouselCustomDots";

const responsive = {
    largest: {
        breakpoint: { max: 4000, min: 1351 },
        items: 5,
    },
    large: {
        breakpoint: { max: 1350, min: 1001 },
        items: 5,
    },
    medium: {
        breakpoint: { max: 1000, min: 681 },
        items: 3,
    },
    small: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
};

export const TeamSection = ({
    hideComponent,
    teamMembersData,
    cardClick,
}: ITemplate.ITeamSectionProps) => {
    const { theme } = useUnlockPageContext();
    const isDesktop = useIsDesktop();

    const hostName = useSelector(SELECTORS.hostName)!;
    const [teamsMisscelenous, setTeamsMisscelenous] = useState<
        ICreatorMiscellaneousData.IProps["offering_section"] | undefined
    >(undefined);

    const miscellaneousPreviewData = useContext(
        MiscellaneousDataContext
    ) as unknown as {
        teams: [{ data: ICreatorMiscellaneousData.IProps["offering_section"] }];
    };

    useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.TEAMS,
                    }),
                });
                if (responseData.status === 200) {
                    const teamsData =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.TEAMS
                        );

                    setTeamsMisscelenous({
                        section_name: teamsData?.data?.section_name,
                        data: teamsData?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "BrandsList",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    useEffect(() => {
        const teams = miscellaneousPreviewData?.teams;
        if (teams?.length) {
            const _teams = teams[0]?.data;
            setTeamsMisscelenous({
                section_name: _teams?.section_name,
                data: {
                    heading: _teams?.data?.heading,
                    description: _teams?.data?.description,
                },
            });
        }
    }, [miscellaneousPreviewData]);

    const multipleTeam = teamMembersData?.length > 1;

    if (hideComponent) return null;

    return (
        <div
            className={styles.root}
            style={{ background: theme?.theme?.color1 }}
        >
            <a id={SECTION_IDS.team} className={styles.sectionAnchor} />
            <div>
                <SectionHeader
                    subtitle={teamsMisscelenous?.section_name || ""}
                    title={teamsMisscelenous?.data?.heading || ""}
                    description={teamsMisscelenous?.data?.description || ""}
                />
                {multipleTeam ? (
                    <Carousel
                        responsive={responsive}
                        infinite
                        arrows={false}
                        className={styles.carouselContainer}
                        autoPlay={teamMembersData.length > 1}
                        showDots={!isDesktop}
                        containerClass={styles.carouselContainerClass}
                        dotListClass={styles.dotListClass}
                        customDot={
                            <CarouselCustomDots
                                index={0}
                                onClick={undefined}
                                active={false}
                            />
                        }
                    >
                        {teamMembersData.map(item => (
                            <TeamMemberCard
                                key={item.username}
                                data={item}
                                cardClick={cardClick}
                            />
                        ))}
                    </Carousel>
                ) : (
                    <>
                        {teamMembersData.map(item => (
                            <TeamMemberCard
                                key={item.username}
                                data={item}
                                cardClick={cardClick}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};
