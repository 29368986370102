import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { classNames } from "repoV2/utils/common/render/classNames";
import { Button } from "repoV2/components/common/Button";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import useOfferingCard from "./useOfferingCard";
import styles from "./eventListing.module.scss";

const OfferingCard = ({
    data,
    onEventListingClick,
    showSkuLabelAsTag,
}: {
    data: ITemplate.IEventListingData;
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
    showSkuLabelAsTag?: boolean;
}) => {
    const {
        handleOfferingClick,
        cover_image,
        sku_type,
        listing_title,
        hide_price,
        price,
        hide_schedule,
        description,
        cta_text,
        updated_price,
        hasDiscountPrice,
    } = useOfferingCard({
        data,
        onEventListingClick,
        showSkuLabelAsTag,
    });

    const metadata = data?.metadata;
    const cardLabelContent = metadata?.card_label?.content;
    const cardLabelColor = metadata?.card_label?.color || "white";
    const cardLabelBgColor = metadata?.card_label?.background || "black";

    return (
        <div className={styles.container}>
            <div className={styles.cardInner}>
                <div className={classNames(styles.cardMain, styles.cardFront)}>
                    <ExlyImage
                        fetchWidth={500}
                        alt={data.title}
                        className={classNames(styles.image)}
                        src={cover_image}
                    />
                    {sku_type ? <div>{sku_type}</div> : null}
                    <div className={classNames(styles.inner, "text-center")}>
                        <div className={styles.flipBoxHeader}>
                            {listing_title}
                        </div>

                        {hide_price ? null : (
                            <>
                                <div
                                    className={classNames(
                                        styles.priceTag,
                                        hasDiscountPrice
                                            ? styles.discountPriceTag
                                            : ""
                                    )}
                                >
                                    {hasDiscountPrice ? updated_price : price}
                                    {hasDiscountPrice ? (
                                        <div
                                            className={styles.scratchedOutPrice}
                                        >
                                            {price}
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.labelsContainer}>
                        {cardLabelContent ? (
                            <div
                                className={styles.label}
                                style={{
                                    color: cardLabelColor,
                                    backgroundColor: cardLabelBgColor,
                                }}
                            >
                                {cardLabelContent}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div
                    className={classNames(styles.cardMain, styles.cardBack)}
                    onClick={handleOfferingClick}
                >
                    <ExlyImage
                        fetchWidth={500}
                        alt={data.title}
                        className={classNames(styles.image)}
                        src={cover_image}
                    />
                    <div className={classNames(styles.innerBack, styles.inner)}>
                        <div className={styles.flipBoxHeader}>
                            {listing_title}
                        </div>
                        {hide_schedule ? (
                            <div
                                className={styles.shortDescription}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        ) : null}
                        <Button className={styles.ctaButton}>{cta_text}</Button>
                    </div>
                    <div className={styles.labelsContainer}>
                        {cardLabelContent ? (
                            <div
                                className={styles.label}
                                style={{
                                    color: cardLabelColor,
                                    backgroundColor: cardLabelBgColor,
                                }}
                            >
                                {cardLabelContent}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

OfferingCard.defaultProps = {
    showSkuLabelAsTag: false,
};

export default OfferingCard;
