import React, { useEffect, useState } from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { useDebounce } from "@Utils/hooks/useDebounce";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { IVerticalImageSlider } from "./IVerticalImageSlider";
import styles from "./verticalImageSlider.module.scss";
import {
    DEFAULT_AUTO_SCROLL_COUNT,
    MIN_SLIDE_COUNT,
    pointers_scroll_container_id,
    single_pointer_margin,
} from "./VerticalImageSlider.constant";

const VerticalImageSlider = (props: IVerticalImageSlider.IProps) => {
    const { theme } = useUnlockPageContext();
    const { data } = props;
    const [sliderData, setSliderData] = useState(data);
    const [currentSlide, setCurrentSlide] = useState({});
    const [imageEffects, setImageEffects] = useState(false);
    const [autoScroll, setAutoScroll] = useState(data.length > 1);

    useEffect(() => {
        setSliderData([...data]);
        if (data.length > 0) setCurrentSlide(data[0]);
    }, [data]);

    // This useEffect is auto scrolling the pointers for user
    useEffect(() => {
        // if we have some sliders (or pointers)
        if (sliderData.length) {
            // here, c is a counter, used to calculate current slide value
            let c = 0;

            // Take the initial element of the pointers
            const initialElement = document.getElementById(`pointer-${c}`);

            // Taking a variable called 'lastActiveHeight', it contains the height of the
            // last element that was active, As scrolling is yet to start, the first element is active
            // why adding single_pointer_margin? - each pointer contains a margin of 28px which is then part of the
            // height of the pointers container which is having scroll property
            let lastActiveHeight =
                (initialElement?.clientHeight || 0) + single_pointer_margin;

            // Starting an interval
            const interval = setInterval(() => {
                // currentSlider is the slider that will be active for user automatically,
                // if c < sliderData.length - 1, meaning we haven't reached last element, then
                // we make the next element as current element, else we redirect to the first element
                const currentSlider = c < sliderData.length - 1 ? c + 1 : 0;
                setCurrentSlide(sliderData[currentSlider]);

                // The main container which is having scroll property, containing all the pointers
                const container = document.getElementById(
                    pointers_scroll_container_id
                );

                // The current active pointer element
                const item = document.getElementById(
                    `pointer-${currentSlider}`
                );
                const containerScrollHeight = container?.scrollHeight || 0;
                const containerClientHeight = container?.clientHeight || 0;

                // Again taking the margin into calculation
                const singleItemHeight =
                    (item?.clientHeight || 0) + single_pointer_margin;

                // if -
                // containerScrollHeight > containerClientHeight - meaning we actually have a scroll
                // currentSlider !== 0 - we are not actually on the first element
                if (
                    containerScrollHeight > containerClientHeight &&
                    currentSlider !== 0
                ) {
                    // We scroll the container by the last active element height
                    container?.scrollBy({
                        top: lastActiveHeight,
                        behavior: "smooth",
                    });
                    // set the current element height in lastActiveElement height
                    lastActiveHeight = singleItemHeight;
                } else {
                    // Else, we scroll to container top, as its the first element
                    container?.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    // change the lastActive element height to the first element height
                    lastActiveHeight =
                        (initialElement?.clientHeight || 0) +
                        single_pointer_margin;
                }

                // if c is not the last element
                if (c < sliderData.length - 1) {
                    // we increase its value by 1
                    c += 1;
                } else {
                    // else, start it again from first element
                    c = 0;
                }
            }, DEFAULT_AUTO_SCROLL_COUNT);

            // auto scroll will only work when user is not 'hovering' on the main container,
            // as if so, then the user is selecting pointers on own
            if (!autoScroll) {
                clearInterval(interval);
            }

            return () => clearInterval(interval);
        }
    }, [sliderData.length, autoScroll]);

    const [debouncedCurrentSlide] = useDebounce(currentSlide, 50);

    // Creating Blur Focus affect
    useEffect(() => {
        setImageEffects(true);

        const timeout = setTimeout(() => {
            setImageEffects(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [debouncedCurrentSlide]);

    const handleMouseOverLinks = (item: React.SetStateAction<{}>) => {
        setAutoScroll(false);
        setCurrentSlide(item);
    };

    const linksPanel = sliderData.map(
        (item: IVerticalImageSlider.IDataItemProp, idx: number) => {
            const isCurrentActive =
                JSON.stringify(debouncedCurrentSlide) === JSON.stringify(item);

            return (
                <div
                    key={item.link.title + item.link.description}
                    className={classNames(
                        styles.links,
                        isCurrentActive ? styles.activeLinks : ""
                    )}
                    onMouseEnter={() => handleMouseOverLinks(item)}
                    onMouseLeave={() => setAutoScroll(true)}
                    onTouchStart={() => handleMouseOverLinks(item)}
                    id={`pointer-${idx}`}
                >
                    <div
                        className={styles.linksTitle}
                        style={{ color: theme.textColor?.color2 }}
                    >
                        {item.link.title}
                    </div>
                    <div className={styles.linksDescription}>
                        {item.link.description}
                    </div>
                </div>
            );
        }
    );

    return (
        <div className={styles.verticalSliderWrap}>
            <div
                className={classNames(
                    styles.container,
                    imageEffects && styles.zoomIn
                )}
                style={{
                    backgroundImage: `url(${getOptimisedImageUrl({
                        imageUrl: debouncedCurrentSlide.coverImage,
                        fetchWidth: 2000,
                    })})`,
                }}
            >
                <div className={styles.sectionTitleContainer}>
                    <p style={{ color: theme.textColor?.color1 }}>
                        {debouncedCurrentSlide.sectionTitle}
                    </p>
                    <p
                        className={styles.sectionDescription}
                        style={{ color: theme.textColor?.color2 }}
                    >
                        {debouncedCurrentSlide.sectionDescription}
                    </p>
                </div>
                {/* Links Panel */}
                <div
                    className={classNames(
                        styles.linksPanel,
                        sliderData?.length < MIN_SLIDE_COUNT &&
                            styles.centeredLinkPanelStyle
                    )}
                    id={pointers_scroll_container_id}
                >
                    {linksPanel}
                </div>
            </div>
        </div>
    );
};

export default VerticalImageSlider;
