import { ITemplate } from "@Templates/ITemplate";
import React, { useEffect, useState, useRef } from "react";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { MEDIA } from "@Utils";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import style from "./StatsSection.module.scss";
import { constants } from "./constants";

const StatsCard = ({
    id,
    value,
    description,
}: {
    id: number;
    value: string;
    description: string;
}) => {
    const { theme } = useUnlockPageContext();
    const [displayedValue, setDisplayedValue] = useState("0");
    const cardRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<NodeJS.Timer | null>(null);

    const startIncValue = () => {
        if (intervalRef.current !== null) return;

        const startValue = parseInt(value, 10) / 5;
        const endValue = parseInt(value.replace(/\D/g, ""), 10);

        let currentValue = startValue;
        const increment = Math.ceil((endValue - startValue) / 10);
        intervalRef.current = setInterval(() => {
            if (currentValue < endValue) {
                currentValue += increment;

                let newCurrentValue;
                if (currentValue === endValue) {
                    newCurrentValue = value;
                } else if (currentValue % 2) {
                    newCurrentValue = currentValue.toString();
                } else {
                    newCurrentValue = (currentValue - increment).toString();
                }

                setDisplayedValue(newCurrentValue);
            } else {
                setDisplayedValue(value);
                if (intervalRef.current) clearInterval(intervalRef.current);
            }
        }, 70);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    if (value.match(/\d+/)) {
                        startIncValue();
                    } else {
                        setDisplayedValue(value);
                    }

                    observer.unobserve(cardRef.current!);
                }
            },
            { threshold: 1 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, [value]);

    return (
        <React.Fragment key={id}>
            <div className={style.strengthContainer} ref={cardRef}>
                <div
                    style={{
                        color: theme?.theme?.color1,
                    }}
                    className={style.strengthHead}
                >
                    {displayedValue}
                </div>
                <div
                    style={{
                        color: theme?.theme?.color1,
                    }}
                    className={style.strengthTxt}
                >
                    {description}
                </div>
            </div>
        </React.Fragment>
    );
};

function StatsSection({
    hideComponent,
    title,
    data,
    backgroundImg,
}: ITemplate.IStatsSectionProps) {
    const { theme } = useUnlockPageContext();
    if (hideComponent) return null;
    const copyData = [...data];

    const limitStats =
        copyData?.length > constants.statsLimiter
            ? copyData?.splice(0, constants.statsLimiter)
            : copyData;

    return (
        <section>
            <div className={style.strengthRoom}>
                <ExlyImage
                    fetchWidth={2000}
                    alt="Strength"
                    className={style.strengthImage}
                    src={backgroundImg || MEDIA.DEFAULT.BACKGROUND.UNLOCK.COVER}
                />
                <div className={style.textContainer}>
                    <h1
                        style={{ color: theme?.theme?.color1 }}
                        className={style.headingStyle}
                    >
                        {title}
                    </h1>
                    <div className={style.strengthCard}>
                        {limitStats?.map(stat => (
                            <StatsCard
                                key={stat.id}
                                id={stat.id}
                                value={stat.value}
                                description={stat.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default StatsSection;
