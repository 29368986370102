import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import {
    CENTER_ALIGN_FEATURES,
    MAX_TOP_FEATURES,
} from "@Templates/unlock/constants/constant";
import { Button } from "repoV2/components/common/Button";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { classNames } from "repoV2/utils/common/render/classNames";
import style from "./TopFeaturesSection.module.scss";

const TopFeaturesSection = ({
    onGetInTouchClick,
    hideComponent,
    data,
}: ITemplate.ITopFeaturesSectionProps) => {
    const { theme } = useUnlockPageContext();
    const prependZero = (x: number, y: number) => (x < y ? `0${x}` : x);

    if (hideComponent) return null;

    // Features must be center aligned if total count is less or equal to three.
    const shouldCenterAlign = data?.length <= CENTER_ALIGN_FEATURES;

    const rootClass = shouldCenterAlign
        ? classNames(style.topFeaturesRoot, style.shouldCenterAlign)
        : style.topFeaturesRoot;

    return (
        <div
            className={rootClass}
            style={{
                background: theme?.theme?.color1,
            }}
        >
            {data.slice(0, MAX_TOP_FEATURES).map((item, index) => (
                <div
                    style={{
                        backgroundColor: theme?.theme?.color1,
                        zIndex: data.length - index,
                    }}
                    className={style.topFeaturesCard}
                    key={item.id}
                >
                    <div className={style.cardUpper}>
                        <div
                            style={{ color: theme?.textColor?.color1 }}
                            className={style.number}
                        >
                            {prependZero(index + 1, 10)}
                        </div>
                        <h4
                            style={{ color: theme?.textColor?.color2 }}
                            className={style.title}
                        >
                            {item.title}
                        </h4>
                        <p
                            style={{ color: theme?.textColor?.color3 }}
                            className={style.description}
                        >
                            {item.description}
                        </p>
                    </div>
                    <div className={style.ctaContainer}>
                        <Button
                            style={{
                                color: theme?.textColor?.color1,
                                border: `1px solid ${theme?.textColor?.color1}`,
                            }}
                            className={style.ctaButton}
                            design="custom"
                            onClick={() => onGetInTouchClick()}
                        >
                            {item?.cta_text || "Get In Touch"}
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TopFeaturesSection;
