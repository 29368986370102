import { IUnlockContextValue } from "./Unlock.interfaces";

export const initial_context_value: IUnlockContextValue = {
    theme: {
        textColor: {
            color1: "",
            color2: "",
            color3: "",
        },
        theme: {
            color1: "",
            color2: "",
        },
    },
    navBarSelected: undefined,
    updateNavBarSelected: undefined,
};
