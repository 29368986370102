import React from "react";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "../index.module.scss";
import { ITablet } from "../Tablet.interfaces";

const TabsHeader = ({
    currentTab,
    tabsTitles,
    overRideSx,
    handleOnChange,
}: ITablet.IHeaderProps) => {
    const { customHeaderClasses, tabSx, container } = overRideSx;

    const titles = tabsTitles?.map(
        (props: { title: string; value: number }) => (
            <Tab sx={tabSx || null} label={props.title} value={props.value} />
        )
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <Tabs
                    value={currentTab}
                    onChange={handleOnChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={container || null}
                    classes={{
                        flexContainer: styles.tabsContainer,
                        ...customHeaderClasses,
                    }}
                >
                    {titles}
                </Tabs>
            </Box>
        </Box>
    );
};

export default TabsHeader;
