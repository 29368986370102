// TODO: Simplify the logic in this component. Currently, 'tabsContent' is not utilized, and the component mainly handles tab selection.
// Consider refactoring to remove unnecessary code and improve component readability.
import React, { useEffect } from "react";

import { LoadingIndicator } from "@Modules/common";
import { ITemplate } from "@Templates/ITemplate";
import { useEventCategorisationKeywords } from "@Utils";

import Carousel from "react-multi-carousel";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Tablet from "@Templates/unlock/components/EventSections/components/Tablet";
import { CAROUSAL_BREAKPOINTS } from "repoV2/constants/screen";
import useMultiCarousal from "repoV2/features/Common/modules/Carousal/MultiCarousal.utils";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import {
    useIsDesktop,
    useIsMediumScreen,
    useIsMobile,
} from "repoV2/utils/common/render/screen";
import { checkIsArrayEmpty } from "repoV2/utils/common/dataTypes/array";
import { classNames } from "repoV2/utils/common/render/classNames";
import OfferingCard from "../OfferingCard";
import CarouselCustomDots from "../../../CarouselCustomDots";
import {
    DESKTOP_VIEW_CARD_COUNT,
    MOBILE_VIEW_CARD_COUNT,
    TABLET_VIEW_CARD_COUNT,
} from "../../eventsSections.constant";
import {
    listing_carousal_page_offset,
    min_listing_carousal_count,
} from "./EventSectionItem.constant";
import moduleStyles from "./EventSectionItem.module.scss";
import styles from "../../eventsSections.module.scss";

const getCount = (isMobile: boolean, isTablet: boolean) => {
    if (isMobile) {
        return MOBILE_VIEW_CARD_COUNT;
    }
    if (isTablet) {
        return TABLET_VIEW_CARD_COUNT;
    }
    return DESKTOP_VIEW_CARD_COUNT;
};

function groupArray(array: any[], groupSize: number) {
    const result = [];
    // Todo: Update this using css grid.
    if (!checkIsArrayEmpty(array)) {
        for (let i = 0; i < array?.length; i += groupSize) {
            const group = array?.slice(i, i + groupSize);
            result.push(group);
        }
    }

    return result;
}

const EventSectionItem = ({
    eventListProp,
    index,
    showKeywords,
    allKeywords,
    onEventListingClick,
    showSkuLabelAsTag,
}: {
    eventListProp: Array<ITemplate.IEventListingData>;
    index: string;
    showKeywords: boolean | null;
    allKeywords: ITemplate.IEventSectionsProps["allKeywords"];
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
    showSkuLabelAsTag: ITemplate.IEventSectionsProps["showSkuLabelAsTag"];
}) => {
    const {
        filteredEventList,
        loading,
        keywords,
        onKeywordClick,
        selectedKeyword,
        resetSelection,
    } = useEventCategorisationKeywords(eventListProp, allKeywords);

    const { activeSlide, handleAfterCarousalChange } = useMultiCarousal({
        totalItems: filteredEventList.length,
    });

    const isDesktop = useIsDesktop();
    const isTablet = useIsMediumScreen();
    const isMobile = useIsMobile();

    const { theme } = useUnlockPageContext();

    /**
     * because we can have a lot of listings for a particualt sku, it will not be as opmised to render them
     * all inside the carousal, so we do it using pagination instead, with carousal showing a limited
     * amount of listings at a time
     *
     * This is the page number state of this pagination
     */
    const [listingCarousalPage, setListingCarousalPage] = React.useState(0);

    useEffect(() => {
        resetSelection();
    }, []);

    const groupedData: ITemplate.IEventListingData[][] = groupArray(
        filteredEventList,
        getCount(isMobile, isTablet)
    );

    const tabsTitles = keywords.map(keyword => ({
        title: keyword,
        value: keyword,
    }));

    const tabletProps = {
        data: {
            tabsTitles,
            tabsContent: [],
        },
        overRideSx: {
            tabSx: {
                "&.MuiTab-root": {
                    color: theme?.textColor?.color2,
                    padding: 0,
                    minWidth: "10px",
                    fontSize: "14px",
                    alignItems: "flex-start",
                },
            },
            container: {
                ".MuiTabs-indicator": {
                    maxWidth: "12px",
                    transform: "translateY(-8px)",
                    backgroundColor: theme?.textColor?.color2,
                },
                ".MuiTabs-flexContainer": {
                    gap: "18px",
                },
            },
        },
        cb: (value: any) => {
            onKeywordClick(value);
        },
        selection: selectedKeyword,
    };

    // If it is a mobile device the we need to pick a batch of '10' - listing_catousal_page_offset listings
    // at a time, else we  do not need to select such a batch
    // Reason, the carousal dots, incase there are a lot of offerings for a listing type, say 100 offerings, then
    // the hundred dots will not fit in mobile screen
    // Secondly, the animation used in listing cards ui is heavy and takes up a lot of memory in mobile browsers, as
    // we try and load all those 100 cards in carousal at same time on mount
    // So, by pagination we are solving both the issues
    const renderGroupedData =
        isMobile && groupedData.length >= min_listing_carousal_count
            ? groupedData.slice(
                  listingCarousalPage * listing_carousal_page_offset,
                  listingCarousalPage * listing_carousal_page_offset +
                      listing_carousal_page_offset
              )
            : groupedData;

    // Fot page number 0, the listings will be starting from 1 to 10
    const paginationStartNumber =
        listingCarousalPage * listing_carousal_page_offset + 1;
    let paginationEndNumber =
        listingCarousalPage * listing_carousal_page_offset +
        listing_carousal_page_offset;
    // Suppose total listing is 143, now for final page the lsiting
    // numbers will be 140-150, it should 140-143
    paginationEndNumber =
        filteredEventList.length < paginationEndNumber
            ? filteredEventList.length
            : paginationEndNumber;

    const handleBackPaginationClick = () => {
        if (listingCarousalPage - 1 >= 0) {
            setListingCarousalPage(listingCarousalPage - 1);
        }
    };

    const handleNextPaginationClick = () => {
        if (
            listingCarousalPage + 1 <=
            Math.floor(filteredEventList.length / listing_carousal_page_offset)
        ) {
            setListingCarousalPage(listingCarousalPage + 1);
        }
    };

    if (checkIsArrayEmpty(filteredEventList)) return <></>;

    if (filteredEventList.length > 1) {
        return (
            <div
                className={classNames(
                    styles.carouselCont,
                    isDesktop && filteredEventList.length < 3
                        ? styles.centerCarouselCont
                        : ""
                )}
            >
                {showKeywords ? <Tablet {...tabletProps} /> : null}
                {loading ? (
                    <LoadingIndicator applyTheme />
                ) : (
                    <div className={moduleStyles.carousalNumberingContainer}>
                        <Carousel
                            responsive={CAROUSAL_BREAKPOINTS}
                            infinite
                            arrows={
                                isMobile &&
                                groupedData.length < min_listing_carousal_count
                            }
                            customLeftArrow={
                                <ChevronLeftRoundedIcon
                                    className={classNames(
                                        moduleStyles.iconStyles,
                                        moduleStyles.customLeftArrow
                                    )}
                                />
                            }
                            customRightArrow={
                                <ChevronRightRoundedIcon
                                    className={classNames(
                                        moduleStyles.iconStyles,
                                        moduleStyles.customRightArrow
                                    )}
                                />
                            }
                            afterChange={handleAfterCarousalChange}
                            showDots={!isMobile}
                            containerClass={
                                isDesktop ? styles.carouselContainerClass : ""
                            }
                            itemClass={styles.carouselItem}
                            autoPlay
                            dotListClass={styles.dotListClass}
                            customDot={
                                <CarouselCustomDots
                                    index={0}
                                    onClick={undefined}
                                    active={false}
                                />
                            }
                        >
                            {renderGroupedData.map((group, groupIdx) => {
                                return (
                                    <div
                                        className={styles.carousalItemContainer}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={groupIdx}
                                    >
                                        {group.map(filteredEvent => (
                                            <OfferingCard
                                                key={filteredEvent.key}
                                                data={filteredEvent}
                                                onEventListingClick={
                                                    onEventListingClick
                                                }
                                                showSkuLabelAsTag={
                                                    showSkuLabelAsTag
                                                }
                                            />
                                        ))}
                                    </div>
                                );
                            })}
                        </Carousel>
                        {isMobile &&
                            groupedData.length < min_listing_carousal_count && (
                                <div className={moduleStyles.customNumbering}>
                                    {activeSlide}
                                </div>
                            )}
                        {/* This will not be rendered in mobile, handling using css */}
                        {groupedData.length >= min_listing_carousal_count && (
                            <div className={moduleStyles.paginationContainer}>
                                <ChevronLeftRoundedIcon
                                    className={moduleStyles.iconStyles}
                                    onClick={handleBackPaginationClick}
                                />
                                <div>
                                    <span
                                        className={
                                            moduleStyles.paginationNumberStyles
                                        }
                                    >
                                        {paginationStartNumber} -{" "}
                                        {paginationEndNumber}{" "}
                                    </span>
                                    <span>of {filteredEventList.length}</span>
                                </div>
                                <ChevronRightRoundedIcon
                                    className={moduleStyles.iconStyles}
                                    onClick={handleNextPaginationClick}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div key={index + selectedKeyword}>
            {showKeywords ? <Tablet {...tabletProps} /> : null}
            {loading ? (
                <LoadingIndicator applyTheme />
            ) : (
                <div className={styles.singleListing}>
                    {filteredEventList.map(filteredEvent => (
                        <OfferingCard
                            key={filteredEvent.key}
                            data={filteredEvent}
                            onEventListingClick={onEventListingClick}
                            showSkuLabelAsTag={showSkuLabelAsTag}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default EventSectionItem;
