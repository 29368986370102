/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { SECTION_IDS, MEDIA } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { Button } from "repoV2/components/common/Button";
import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import {
    TEMPLATE_CONFIG,
    TEMPLATE_KEYS,
} from "repoV2/features/CreatorPages/CreatorPages.constants";
import commonStyles from "../../../yoga/root/yoga.module.scss";
import styles from "./aboutUs.module.scss";

const AboutUs = ({
    coverImg,
    shortDescription,
    title,
    mobileCoverImage,
    getInTouchCtaText,
    onGetInTouchClick,
    hideGetInTouch,
}: ITemplate.IAboutSectionProps): JSX.Element => {
    const { theme } = useUnlockPageContext();
    const optimisedImageUrl = getOptimisedImageUrl({
        imageUrl: coverImg || MEDIA.DEFAULT.BACKGROUND.UNLOCK.COVER,
        fetchWidth: TEMPLATE_CONFIG[TEMPLATE_KEYS.ELEMENTARY].coverImage.width,
    });

    const mobileOptimisedImageUrl = getOptimisedImageUrl({
        imageUrl:
            mobileCoverImage ||
            coverImg ||
            MEDIA.DEFAULT.BACKGROUND.UNLOCK.COVER,
        fetchWidth:
            TEMPLATE_CONFIG[TEMPLATE_KEYS.UNLOCK].mobileCoverImage?.width || 0,
    });

    const aboutSection = (
        <div className={styles.heroContent}>
            <div>
                {title ? (
                    <h1
                        style={{ color: theme?.theme?.color1 }}
                        className={styles.title}
                    >
                        {title}
                    </h1>
                ) : null}
                {shortDescription ? (
                    <p
                        style={{ color: theme?.theme?.color1 }}
                        className={classNames(
                            styles.shortDescription,
                            styles.desktopOnly
                        )}
                    >
                        {shortDescription}
                    </p>
                ) : null}
            </div>
            {hideGetInTouch ? null : (
                <div className={styles.freeProgramContainer}>
                    <Button
                        style={{
                            color: theme?.textColor?.color2,
                            background: theme?.theme?.color1,
                        }}
                        onClick={() => onGetInTouchClick()}
                        className={styles.freeProgram}
                    >
                        {getInTouchCtaText}
                    </Button>
                </div>
            )}
        </div>
    );

    return (
        <section
            style={{
                background: theme?.theme?.color1,
            }}
        >
            <a id={SECTION_IDS.about} className={commonStyles.sectionAnchor} />
            <div
                className={classNames(styles.heroSection, styles.hideInMobile)}
                style={{
                    backgroundImage: `url(${optimisedImageUrl})`,
                }}
            >
                {aboutSection}
            </div>
            <div
                className={classNames(styles.heroSection, styles.hideInDesktop)}
                style={{
                    backgroundImage: `url(${
                        mobileOptimisedImageUrl ||
                        optimisedImageUrl ||
                        MEDIA.DEFAULT.BACKGROUND.UNLOCK.COVER
                    })`,
                }}
            >
                {aboutSection}
            </div>
        </section>
    );
};

export default AboutUs;
