import React, { useContext, useEffect, useState } from "react";
import { ITemplate } from "@Templates/ITemplate";
import {
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    SELECTORS,
    EVENT_CATEGORISATION_TYPE,
} from "@Utils";

import { useUnlockPageContext } from "@Templates/unlock/root/context";
import { blue_theme_plan_type } from "@Modules/common/Navbar/templates/NavbarUnlock/components/constants";
import Tabs from "repoV2/components/common/Tabs";
import { useCategorisedEventList } from "@Modules/hostPage/CategorisedEventList/root/useCategorisedEventList";
import { useSelector } from "react-redux";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "@Modules/hostPage/CreatorMiscellaneousData/data";
import { logError } from "repoV2/utils/common/error/error";
import { ICreatorMiscellaneousData } from "@Modules/hostPage/CreatorMiscellaneousData/ICreatorMiscellaneousData";
import { classNames } from "repoV2/utils/common/render/classNames";
import { LISTING_TYPES } from "repoV2/features/Listing/Listing.constants";
import { MiscellaneousDataContext } from "@Pages/host";
import styles from "./eventsSections.module.scss";
import SectionHeader from "../SectionHeader";
import EventSectionItem from "./components/EventSectionItem";
import { SCROLLING_DIV_ID } from "./eventsSections.constant";

export const EventSections = ({
    sectionTitle,
    eventListings,
    onEventListingClick,
    allKeywords,
    allEventCategories,
    categoriseType,
    showSkuLabelAsTag,
}: ITemplate.IEventSectionsProps) => {
    const { theme, navBarSelected, updateNavBarSelected } =
        useUnlockPageContext();
    const hostName = useSelector(SELECTORS.hostName)!;

    const [metaData, setMetaData] =
        useState<ICreatorMiscellaneousData.IProps["offering_section"]>();

    const miscellaneousPreviewData = useContext(
        MiscellaneousDataContext
    ) as unknown as {
        offeringsSection: [
            {
                data: ICreatorMiscellaneousData.IProps["offering_section"];
            }
        ];
    };

    useEffect(() => {
        const offeringsSection = miscellaneousPreviewData?.offeringsSection;
        if (offeringsSection?.length) {
            const _offeringsSection = offeringsSection[0]?.data;
            setMetaData({
                section_name: _offeringsSection?.section_name,
                data: {
                    heading: _offeringsSection?.data?.heading,
                    description: _offeringsSection?.data?.description,
                },
            });
        }
    }, [miscellaneousPreviewData]);

    useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.OFFERING_SECTION,
                    }),
                });
                if (responseData.status === 200) {
                    const offeringSection =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.OFFERING_SECTION
                        );

                    setMetaData({
                        section_name: offeringSection?.data?.section_name,
                        data: offeringSection?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "Offerings Miscellaneous data",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    const categorisedEventList = useCategorisedEventList({
        allCategories: allEventCategories,
        eventListings,
        categoriseType,
    });

    const handleTabChange = (
        e: React.SyntheticEvent,
        value: string | number
    ) => {
        console.log("value: ", value);
        const currentSelectedElement =
            document.getElementById(SCROLLING_DIV_ID);
        if (currentSelectedElement) {
            currentSelectedElement.scrollIntoView({
                inline: "center",
                behavior: "smooth",
                block: "start",
            });
        }
        updateNavBarSelected(value);
    };

    const generateTabsData = () => {
        const tabsTitles: { title: string; value: number | undefined }[] = [];
        const tabsContent: (JSX.Element | null)[] = [];

        if (Array.isArray(categorisedEventList)) {
            categorisedEventList.forEach((item, i) => {
                let tabTitleValue = i;
                // incase the event categorization is based upon sku types,
                // then we use the listing type as the tab id, becuase listing id is unique for sku type
                // else we use the index
                if (
                    [
                        EVENT_CATEGORISATION_TYPE.SKU_TYPE,
                        EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS,
                    ].includes(categoriseType)
                ) {
                    // The lsiting type of Plans is '9999' for other Host page templates, but for blue theme
                    // we are not keeping it the same, as we show the data sequentially through an array and
                    // giving 9999 as index is not ideal
                    tabTitleValue =
                        item?.listingType === LISTING_TYPES.PLANS
                            ? blue_theme_plan_type
                            : item?.listingType || 0;
                }
                if (item) {
                    tabsTitles.push({
                        title: item.category,
                        value: tabTitleValue,
                    });
                    tabsContent[tabTitleValue] = (
                        <EventSectionItem
                            eventListProp={item.listings}
                            key={item.index + item.category}
                            index={item.index}
                            showKeywords={item?.showKeywords}
                            allKeywords={allKeywords}
                            onEventListingClick={onEventListingClick}
                            showSkuLabelAsTag={showSkuLabelAsTag}
                        />
                    );
                } else {
                    // We are pushing null here to make certain that we have the same length of array regardless of
                    // the 'item' existing
                    tabsContent.push(null);
                }
            });
        }
        // Initial non null tab to be selected
        let selection = tabsContent.findIndex(element => element !== null);
        if (
            [
                EVENT_CATEGORISATION_TYPE.SKU_TYPE,
                EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS,
            ].includes(categoriseType)
        ) {
            // Here, the listings are sorted as per sku, so take default value accordingly
            selection = tabsTitles?.[0]?.value || 0;
        }

        return {
            tabsTitles,
            tabsContent,
            selection,
        };
    };

    const generateTabs = generateTabsData();

    const overRideSx = {
        tabSx: {
            root: {
                color: theme?.textColor?.color2,
                fontSize: "12px",
                minWidth: "unset",
                fontFamily: "inherit",
                fontWeight: "500",
                letterSpacing: "1.68px",
                minHeight: "34px",
                padding: "6px 18px",
                overflowWrap: "anywhere",
                textTransform: "uppercase",
                cursor: "pointer",
            },
            selected: {
                color: theme?.theme?.color1,
                opacity: "1",
                backgroundColor: theme?.textColor?.color1,
                borderRadius: "27px",
            },
        },
    };

    // if navBarSelected consists of index of offering, using it as current tab
    // otherwise using default selection offering value
    const currentTab: number =
        Number(navBarSelected) >= 0
            ? Number(navBarSelected)
            : generateTabs.selection;

    // Adding a null check incase no offering is present.
    if (!categorisedEventList) return <></>;

    return (
        <div
            style={{ background: theme?.theme?.color2 }}
            className={classNames(
                styles.eventSectionContainer,
                styles.extraPadding
            )}
            id="eventSectionContainer"
        >
            <SectionHeader
                subtitle={metaData?.section_name || "offerings"}
                title={metaData?.data?.heading || sectionTitle}
                description={metaData?.data?.description}
                descriptionClass={styles.descriptionClass}
            />
            {generateTabs.tabsContent.length > 0 ? (
                <>
                    <Tabs
                        currentTab={currentTab}
                        tabsTitles={generateTabs.tabsTitles}
                        overRideSx={overRideSx}
                        handleOnChange={handleTabChange}
                        classes={{
                            root: styles.tabsRootStyle,
                        }}
                    />
                    {generateTabs.tabsContent?.[+currentTab]}
                </>
            ) : (
                <EventSectionItem
                    eventListProp={
                        (
                            categorisedEventList as {
                                listings: ITemplate.IEventListingData[];
                                showKeywords: null;
                            }
                        )?.listings
                    }
                    index="show_all"
                    showKeywords={null}
                    allKeywords={allKeywords}
                    onEventListingClick={onEventListingClick}
                    showSkuLabelAsTag={showSkuLabelAsTag}
                />
            )}
        </div>
    );
};
