import { useEffect, useState } from "react";

const useOnScroll = ({
    scrollHeight,
    ref,
}: {
    scrollHeight: number;
    ref: null | React.RefObject<HTMLDivElement>;
}) => {
    const [reached, setReached] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (ref?.current) {
                const currentScrollPos = ref.current.scrollTop;
                setReached(currentScrollPos > scrollHeight);
            }
        };

        if (ref?.current) {
            ref.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (ref?.current) {
                ref.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [ref, scrollHeight]);

    return {
        reached,
    };
};

export default useOnScroll;
